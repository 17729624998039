import React, { useState } from 'react';


import { Row, Col, Button, Card, CardBody, CardHeader, Input, Label } from 'reactstrap';


import {MdDeleteForever} from 'react-icons/md';
import {FaEdit} from 'react-icons/fa';

const MonitoringHeaderDtl = ({data, delete_d, edit_d, ...props}) => {

    return(
        <Card className='mb-2'>
            <CardBody style={{padding: '8px'}}>
                <Row>
                    <Col md={6}>
                        <div><b>Measuring Point Details</b></div>
                        <div>{data?.m_desc}</div>            
                    </Col>
                    <Col md={4}>
                        <div><b>Value</b></div>
                        <div>{data?.recommend_value +" "+ data?.uom_desc}</div>              
                    </Col>
                    <Col md={1} className="d-flex align-items-center">
                       <FaEdit className='cursor' color="#4d8d6e" size={30} onClick={edit_d} />           
                    </Col>
                    <Col md={1} className="d-flex align-items-center">
                       <MdDeleteForever className='cursor' color="#ff4136" size={30} onClick={delete_d} />           
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}
export default MonitoringHeaderDtl;