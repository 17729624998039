import React, {useState, useEffect} from 'react';
import { notification } from '../../hocs/notification';

import axios from 'axios';
import { mainurl } from '../../../Mainurl';

import { Row, Col, Card, CardBody, CardHeader, Input, Label, Button, Form, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';

import MonitoringHeader from './MonitoringHeader';
import MonitoringHeaderDtl from './MonitoringHeaderDtl';

import { FaPlusCircle } from 'react-icons/fa';


const AddNewPOP = ({data, back, ...props}) => {

    const [loading, setLoading] = useState(false);

    const [cropTypeOpt, setCropTypeOpt] = useState('');
    const [cropType, setCropType] = useState('');
    const [cropOpt, setCropOpt] = useState('');
    const [crop, setCrop] = useState('');
    const [cropVarietyOpt, setCropVarietyOpt] = useState('');
    const [cropVariety, setCropVariety] = useState('');
    const [popDays, setPopDays] = useState('');
    //
    
    const [measureHeaderOpt, setMeasureHeaderOpt] = useState('');
    const [measureDetailOpt, setMeasureDetailOpt] = useState('');


    // header
    const [measureHeader, setMeasureHeader] = useState('');
    const [measureHeaderTxt, setMeasureHeaderTxt] = useState('');
    const [seqNumber, setSeqNumber] = useState('');
    const [seqDay, setSeqDay] = useState('');
    // details
    const [measureDtl, setMeasureDtl] = useState('');
    const [measureDtlTxt, setMeasureDtlTxt] = useState('');
    const [rmdValue, setRmdValue] = useState('');
    const [rmdUom, setRmdUom] = useState('');
    const [rmdUomTxt, setRmdUomTxt] = useState('');
    //
    const [currMcode, setCurrMcode] = useState('');
    const [popDataHtml, setPopDataHtml] = useState([]);

    const [headerModal, setHeaderModal] = useState(false);
    const [editHeaderFlag, setEditHeaderFlag] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const [editDetailFlag, setEditDetailFlag] = useState(false);

    useEffect(() => {
        get_measure_header_list();
        get_pop_details();
    }, []);

    const get_pop_details = () => {
        let postData = [{
            "org_id": data.org_id,
            "org_pop_code": data.org_pop_code,
            "crop_code": data.crop_code,
            "crop_variety_code": data.crop_variety_code
        }]
        axios({
            method: 'post',
            url: mainurl + 'Traceability/get_pop_details',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row,index) => {
                    return(
                        <MonitoringHeader data={row} 
                            delete_h={()=>delete_header(index)} 
                            edit_h={()=> edit_header(row)} 
                            add_details={()=> {setDetailModal(true); setEditDetailFlag(false);setCurrMcode(row.m_code); get_measure_detail_list(row.m_code);}} >
                            {row.monitoring_detail?.map((row1, index1) => {
                            return (<MonitoringHeaderDtl data ={row1} 
                                    delete_d={()=>delete_detail(index, index1)} 
                                    edit_d={()=>{edit_detail(row1);setCurrMcode(row.m_code);get_measure_detail_list(row.m_code);} } />)
                            })}
                        </MonitoringHeader>
                    )
                });
                setPopDataHtml(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_measure_header_list = () => {
        axios({
            method: 'post',
            url: mainurl + 'Master/get_measure_h_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return (
                        <option value={row.m_code}> {row.m_desc}</option>
                    )
                });

                setMeasureHeaderOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_measure_detail_list = (code) => {
        let postData = [{"m_code": code}];
        axios({
            method: 'post',
            url: mainurl + 'Master/get_measure_d_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return (
                        <option value={row.md_code}> {row.m_desc}</option>
                    )
                });

                setMeasureDetailOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }
    // add_monitoring_header_handler

    const add_monitoring_header_handler = (e) => {
        e.preventDefault();
        setLoading(true);
        let postData = [{
            "org_id": data.org_id,
            "m_code": measureHeader,
            "m_seq_no": seqNumber,
            "m_seq_day": seqDay,
            "org_pop_code": data.org_pop_code,
            "crop_code": data.crop_code,
            "crop_variety_code": data.crop_variety_code,
        }];

        axios({
            method: 'post',
            url: mainurl + 'Traceability/create_pop_header',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data[0].retcode === "101"){
                    let notify = notification({ message: "POP header created successfully.", type: 'info', time:true, position:'center' });
                    notify();
                    get_pop_details();
                    setHeaderModal(false);
                    setMeasureHeader('0');
                    setSeqNumber('');
                    setSeqDay('');
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const add_monitoring_detail_handler = (e) => {
        e.preventDefault();
        setLoading(true);
        
        let postData = [{
            "org_id": data.org_id,
            "m_code": currMcode,
            "md_code": measureDtl,
            "recommend_value": rmdValue,
            "recommend_uom": rmdUom,
            "org_pop_code": data.org_pop_code,
            "crop_code": data.crop_code,
            "crop_variety_code": data.crop_variety_code,
        }];
        console.log(postData);
        axios({
            method: 'post',
            url: mainurl + 'Traceability/create_pop_detail',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data[0].retcode === "101"){
                    let notify = notification({ message: "Added successfully.", type: 'info', time:true, position:'center' });
                    notify();
                    get_pop_details();
                    setDetailModal(false);

                    setMeasureDtl('0');
                    setRmdValue('');
                    setRmdUom('');
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const edit_header = () => {

    }

    const update_edit_header = () => {
        
    }

    const edit_detail = (dtl) => {
        
    }

    const update_edit_detail = () => {
        
    }

    const delete_header = (id) => {
       
    }

    const delete_detail = (id, id1) => {
        
    }

    return(
        <>
            <Row className="p-3">
                <Col xs={12}>
                    <Card className='mb-3'>
                        <CardHeader className='d-flex justify-content-between align-items-center'>
                            <div>Add POP Details</div>
                            <Button className='text-white' color='primary' size="sm" onClick={back}> Back </Button>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}>
                                    <div className='mb-1'><b>Crop Details</b></div>
                                    <div>{data?.crop_name} ( { data?.crop_variety_name} )</div>
                                </Col>
                                <Col md={2}>
                                    <div className='mb-1'><b>POP Days</b></div>
                                    <div>{data?.pop_days}</div>
                                </Col>
                                <Col xs={3} >
                                    <Button className='text-white' color='primary' size="sm" onClick={() => {setHeaderModal(true); setEditHeaderFlag(false);}}> <FaPlusCircle size={20} /> Add Monitoring Header </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {popDataHtml}
                </Col>
            </Row>

            <Modal
                centered
                scrollable
                size="lg"
                isOpen={headerModal}
                toggle={() => setHeaderModal(!headerModal)}
            >
                <ModalHeader toggle={() => setHeaderModal(!headerModal)}>
                    Add Monitoring Header
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={add_monitoring_header_handler} >

                        <Row>
                            <Col md={3} className="mb-2">
                                <Label for="measureHeader"> Measuring Header Name </Label>
                                <Input id="measureHeader" bsSize="sm" type="select" value={measureHeader} onChange={(e) => { setMeasureHeader(e.target.value); setMeasureHeaderTxt(e.target.selectedOptions[0].text) }}>
                                    <option value="0"> --Select measure Header Name --</option>
                                    {measureHeaderOpt}

                                </Input>
                            </Col>
                            <Col md={3} className="mb-2">
                                <Label for="seqNumber"> Measuring Sequence Number </Label>
                                <Input id="seqNumber" bsSize="sm" type="text" value={seqNumber} onChange={(e) => setSeqNumber(e.target.value)} />
                            </Col>
                            <Col md={3} className="mb-2">
                                <Label for="seqDay"> Measuring Sequence Day </Label>
                                <Input id="seqDay" bsSize="sm" type="text" value={seqDay} onChange={(e) => setSeqDay(e.target.value)} />
                            </Col>
                            
                            <Col md={3} className="mb-2">
                                <Button className='text-white mt-4' color='primary' size="sm" disabled={loading}> Add {loading && <Spinner className='ms-1' color="secondary" size="sm">Loading...</Spinner>} </Button>
                            </Col>
                              
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>


            <Modal
                centered
                scrollable
                size="lg"
                isOpen={detailModal}
                toggle={() => setDetailModal(!detailModal)}
            >
                <ModalHeader toggle={() => setDetailModal(!detailModal)}>
                    Add Monitoring Details
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={add_monitoring_detail_handler} >

                        <Row>
                            <Col md={3} className="mb-2">
                                <Label for="measureDtl"> Measuring Detail Name </Label>
                                <Input id="measureDtl" bsSize="sm" type="select" value={measureDtl} onChange={(e) => {setMeasureDtl(e.target.value); setMeasureDtlTxt(e.target.selectedOptions[0].text)}}>
                                    <option value="0"> --Select measure Detail Name --</option>
                                    {measureDetailOpt}

                                </Input>
                            </Col>
                            <Col md={3} className="mb-2">
                                <Label for="recomValue"> Recommended Value </Label>
                                <Input id="recomValue" bsSize="sm" type="text" value={rmdValue} onChange={(e) => setRmdValue(e.target.value)} />
                            </Col>
                            <Col md={3} className="mb-2">
                                <Label for="recomUom"> Recommended UOM </Label>
                                <Input id="recomUom" bsSize="sm" type="select" value={rmdUom} onChange={(e) => {setRmdUom(e.target.value); setRmdUomTxt(e.target.selectedOptions[0].text)}}>
                                    <option value=""> --Select UOM --</option>
                                    <option value="0"> -- </option>
                                    <option value="1"> Gm </option>
                                    <option value="2"> Kg </option>
                                    <option value="3"> Liter </option>
                                    <option value="4"> Feet </option>
                                    <option value="5"> Cm </option>
                                    <option value="6"> Seeds </option>
                                    <option value="7"> % </option>
                                </Input>
                            </Col>
                           
                            <Col md={3} className="mb-2">
                                <Button className='text-white mt-4' color='primary' size="sm" disabled={loading}> Add {loading && <Spinner className='ms-1' color="secondary" size="sm">Loading...</Spinner>} </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}
export default AddNewPOP;