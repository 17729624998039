import React, {useState, useEffect} from 'react';
import { notification } from '../../hocs/notification';
import axios from 'axios';
import { mainurl } from '../../../Mainurl';

import { Card, CardHeader, CardBody, Button, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

const AddCompliance = ({back, ...props}) => {
    const [complianceType, setComplianceType] = useState('');
    const [complianceTypeOpt, setComplianceTypeOpt] = useState('');
    const [complianceNo, setComplianceNo] = useState('');
    const [complianceDesc, setComplianceDesc] = useState('');
    const [frequency, setFrequency] = useState('');
    const [complianceStatus, setComplianceStatus] = useState('');
    const [complianceFile, setComplianceFile] = useState('');

    useEffect(()=>{
        get_compliance_type_list();
        
    }, [])

    const get_compliance_type_list = () => {
        axios({
            method: 'post',
            url: mainurl + 'Master/compliance_type_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option key={index} value={row.compliance_type_id}> {row.compliance_name} </option>
                    )
                });

                setComplianceTypeOpt(list);
            })
            .catch(error => {
                //let notify = notification({ message: "compliance type list not Found! Try Again", type: 'error' });
                //notify();
            });
    }
    const add_compliance_handler = (e) => {
        e.preventDefault();

        let postData = [{
            "org_id": sessionStorage.getItem('org_id') ,
            "compliance_type_id": complianceType,
            "compliance_no": complianceNo,
            "compliance_desc": complianceDesc,
            "frequency": frequency,
            "status": complianceStatus
        }]

        console.log(postData);
        axios({
            method: 'post',
            url: mainurl + 'Master/add_compliance',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
        .then(function (response) {
            let res = response.data[0].retcode;
            if(res === "101"){
                let notify = notification({ message: "compliance added Successfully.", type: 'info', time:true, position:'center' });
                notify();
                back();
            }
        })
        .catch(error => {
            let notify = notification({ message: "Submit failed! Try Again", type: 'error',  time:true });
            notify();
        });
    }

    return(
        <>
            <Card color="primary" outline>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <div> Add Statutory Compliance </div>
                    <div> <Button className='text-white me-3' color='primary' size="sm" onClick={back} > Back</Button> </div>
                </CardHeader>
                <CardBody>
                    <Form onSubmit={add_compliance_handler}>
                        <Row>
                            <FormGroup row>
                                <Label for="complianceType" sm={2} > Compliance Type </Label>
                                <Col sm={4}>
                                    <Input id="complianceType" bsSize="sm" type="select" value={complianceType} onChange={(e) => setComplianceType(e.target.value)}>
                                        <option value="0"> --Select Compliance Type--</option>
                                        {complianceTypeOpt}
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="complianceNo" sm={2} > Compliance Number </Label>
                                <Col sm={4}>
                                    <Input id="complianceNo" type='text' bsSize="sm"  value={complianceNo} onChange={(e) => setComplianceNo(e.target.value)} required />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="complianceDesc" sm={2} > Compliance Description </Label>
                                <Col sm={4}>
                                    <Input id="complianceDesc" type='textarea' bsSize="sm"  value={complianceDesc} onChange={(e) => setComplianceDesc(e.target.value)} required />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="frequency" sm={2} > Frequency </Label>
                                <Col sm={4}>
                                    <Input id="frequency" bsSize="sm" type="select" value={frequency} onChange={(e) => setFrequency(e.target.value)}>
                                        <option value="0"> --Select Frequency--</option>
                                        <option value="1"> Yearly </option>
                                        <option value="2"> Half Yearly </option>
                                        <option value="3"> Quarterly (Three monthly) </option>
                                        <option value="4"> Monthly </option>
                                        <option value="5"> Weekly </option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="status" sm={2} > Status </Label>
                                <Col sm={4}>
                                    <Input id="status" bsSize="sm" type="select" value={complianceStatus} onChange={(e) => setComplianceStatus(e.target.value)}>
                                        <option value="0"> --Select Status--</option>
                                        <option value="1"> Pending </option>
                                        <option value="2"> Completed </option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="complianceFile" sm={2} > Add File </Label>
                                <Col sm={4}>
                                    <Input id="complianceFile" bsSize="sm" type="file" value={complianceFile} onChange={(e) => setComplianceFile(e.target.value)} />
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <hr />
                            <Col md={2} className="mb-2">
                                <Button className='text-white' color='primary' size="sm" block> Submit </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>        
        </>
    )
}
export default AddCompliance;