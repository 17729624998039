import React, { useState, useEffect } from 'react';

import axios from 'axios';
import {mainurl} from '../../../Mainurl';

import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../../style/color';
import { Row, Col, Card, CardHeader, CardBody, Button, Form, Label, Input, CardFooter, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { FaEye } from 'react-icons/fa';

const AddPlanning = ({ back, ...props }) => {

    const [loading, setLoading] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [cropTypeOpt, setCropTypeOpt] = useState('');
    const [cropType, setCropType] = useState('');
    const [cropOpt, setCropOpt] = useState('');
    const [crop, setCrop] = useState('');
    const [cropVarietyOpt, setCropVarietyOpt] = useState('');
    const [cropVariety, setCropVariety] = useState('');
    const [planningDate, setPlanningDate] = useState('');
    const [area, setArea] = useState('');
    const [areaUom, setAreaUom] = useState('');
    
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [block, setBlock] = useState('');
    
    const [stateOpt, setStateOpt] = useState('');
    const [districtOpt, setDistrictOpt] = useState('');
    const [blockOpt, setBlockOpt] = useState('');
    
    const [farmerList, setFarmerList] = useState('');
    const [selectedRows, setSelectedRows] = useState('');
    
    const [selectedFarmerList, setSelectedFarmerList] = useState([]);

    useEffect(()=>{
        get_crop_type();
        get_state_list();
    },[])

    const get_crop_type = () => {
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": "10001", "lang_id": 1}];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_type_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.crop_type_id}> {row.crop_type_name}</option>
                    )
                });

                setCropTypeOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_crop_list = (e) => {
        setCropOpt('');
        setCrop("0");
        setCropType(e.target.value);
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": "101", "crop_type_id": e.target.value, "lang_id": 1}];

        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_master_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.crop_code}> {row.crop_name}</option>
                    )
                });

                setCropOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const crop_variety_list = (e) => {
        setCrop(e.target.value);
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": "101", "crop_code": e.target.value, "lang_id": 1}]
        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_variety_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.crop_variety_code}> {row.crop_variety_name}</option>
                    )
                });

                setCropVarietyOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_state_list = (e) => {
        
        let postData = [{"country_code": "1"}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_state_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.state_code}> {row.state_name}</option>
                    )
                });

                setStateOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_dist_list = (e) => {
        setState(e.target.value);
        let postData = [{"state_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_dist_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.district_code}> {row.district_name}</option>
                    )
                });

                setDistrictOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_block_list = (e) => {
        setDistrict(e.target.value);
        let postData = [{"dist_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.block_code}> {row.block_name}</option>
                    )
                });

                setBlockOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_farmer_list = (e) => {
        setLoading(true);
        setFarmerList('');
        e.preventDefault();
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": "101", "dist_id": 0, "block_id": 0, "vill_id":0}];
        axios({
            method: 'post',
            url: 'https://api2.boxfarming.in/wsapi/v2/index.php/Farmer/box_farmer_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        {
                            f_code: row.farmer_code,
                            name: row.f_name +" ("+ row.f_name_ne +")",
                            mobile: row.mobile_no,
                            spouse_name: row.f_spouse_name,
                            location: row.state_name +"/"+ row.district_name +"/"+ row.block_name +"/"+ row.village_name,
                        }
                    )
                });

            setFarmerList(list);
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
            //notify();
        });
    }

    const add_selected_farmer = () => {
        console.log(selectedRows);
        let temp = selectedFarmerList;
        //check pre existed farmer
        selectedRows.map(row => {
            let found = selectedFarmerList.find(el => el.f_code === row.f_code);
            if (!found) temp.push(row);
        });
        
        setSelectedFarmerList(temp);
        setViewModal(false);
    }

    const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

    const add_planning = () => {

        let farmer_list = [];
        selectedFarmerList.map(row => {
            farmer_list.push({"f_id": row.f_code}) 
        });

        let postData = [{
            "org_id": sessionStorage.getItem('org_id'),
            "user_id": 10001,
            "crop_type_code": cropType,
            "crop_code": crop,
            "crop_variety": cropVariety,
            "planning_date": planningDate,
            "area": area,
            "area_uom": areaUom,
            "total_farmer": farmer_list.length,
            "farmer_list": farmer_list
        }];

        console.log(postData);

        axios({
            method: 'post',
            url: mainurl + 'Activity/add_planning',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                back();   
                setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
            //notify();
        });
    }

    return (
        <>
            <Card className='mb-3' color="primary" outline>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <div>Add New Plan</div>
                    <div> <Button className='text-white me-3' color='primary' size="sm" onClick={back}> Back </Button> </div>
                </CardHeader>
                <CardBody>
                    
                        <Row>
                            <Col sm={3} className="mb-2">
                                <Label for="type"> Crop Type </Label>
                                <Input id="type" bsSize="sm" type="select" value={cropType} onChange={get_crop_list}>
                                    <option value="0"> --Select Crop Type--</option>
                                    {cropTypeOpt}
                                </Input>
                            </Col>
                            <Col sm={3} className="mb-2">
                                <Label for="crop"> Crop </Label>
                                <Input id="crop" bsSize="sm" type="select" value={crop} onChange={crop_variety_list}>
                                    <option value="0"> --Select Crop --</option>
                                    {cropOpt}
                                </Input>
                            </Col>
                            <Col sm={3} className="mb-2">
                                <Label for="cropVariety"> Crop Variety </Label>
                                <Input id="cropVariety" bsSize="sm" type="select" value={cropVariety} onChange={(e) => setCropVariety(e.target.value)}>
                                    <option value="0"> --Select Crop --</option>
                                    {cropVarietyOpt}
                                </Input>
                            </Col>
                            <Col sm={3} className="mb-2">
                                <Label for="planning_date"> Planning Date </Label>
                                <Input type="date" id="planning_date" bsSize="sm" value={planningDate} onChange={(e)=> setPlanningDate(e.target.value)} />
                            </Col>
                            <Col sm={3} className="mb-2">
                                <Label for="area"> Area </Label>
                                <Input type="text" id="area" bsSize="sm" value={area} onChange={(e)=> setArea(e.target.value)} />
                            </Col>
                            <Col sm={3} className="mb-2">
                                <Label for="uom"> UOM </Label>
                                <Input id="uom" bsSize="sm" type="select" value={areaUom} onChange={(e) => setAreaUom(e.target.value)}>
                                    <option value="0"> --Select UOM --</option>
                                    <option value="101"> Decimal </option>
                                    <option value="102"> Acre </option>
                                    <option value="103"> Hector </option>
                                    <option value="104"> Square feet </option>
                                </Input>
                            </Col>
                            <Col sm={3}>
                                <Button className='text-white mt-4' color='primary' size="sm" onClick={()=>setViewModal(true)} block> Add Farmers  </Button>
                            </Col>
                        </Row>
                </CardBody>
            </Card>

            <Card color="primary" outline>
                <CardHeader> Selected Farmers List </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs={12}>
                        <DataTable
                                title=""
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                    name: 'Code',
                                    selector: row=>row.f_code,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Name',
                                    selector: row=>row.name,
                                    sortable: false,
                                    width: "20%"
                                    },
                                    {
                                    name: 'Mobile Number',
                                    selector: row=>row.mobile,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Spouse Name',
                                    selector: row=>row.spouse_name,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Location',
                                    selector: row=>row.location,
                                    sortable: false,
                                    width: "25%"
                                    },
                                ]}
                                data={selectedFarmerList}
                                highlightOnHover
                                progressPending={loading}
                            />
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className='text-end'>
                    <Button className='text-white' color='primary' size="sm" onClick={add_planning}> Add Plan </Button>
                </CardFooter>
            </Card>

            <Modal
                centered
                scrollable
                size="xl"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    Farmer Details
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={get_farmer_list}>
                        <Row>
                            <Col sm={3} className="mb-2">
                                <Label for="state"> State </Label>
                                <Input id="state" bsSize="sm" type="select" value={state} onChange={get_dist_list}>
                                    <option value="0"> --Select State--</option>
                                    {stateOpt}
                                </Input>
                            </Col>
                            <Col sm={3} className="mb-2">
                                <Label for="district"> District </Label>
                                <Input id="district" bsSize="sm" type="select" value={district} onChange={get_block_list}>
                                    <option value="0"> --Select District--</option>
                                    {districtOpt}
                                </Input>
                            </Col>
                            <Col sm={3} className="mb-2">
                                <Label for="block"> Block </Label>
                                <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => setBlock(e.target.value)}>
                                    <option value="0"> --Select Block--</option>
                                    {blockOpt}
                                </Input>
                            </Col>
                            <Col md={2} className="mb-2">
                                <Button className='text-white mt-4' color='primary' size="sm" block> Get </Button>
                            </Col>
                        </Row>
                    </Form>
                <Row>
                    <Col xs={12}>
                        <DataTable
                                title=""
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                    name: 'Code',
                                    selector: row=>row.f_code,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Name',
                                    selector: row=>row.name,
                                    sortable: false,
                                    width: "20%"
                                    },
                                    {
                                    name: 'Spouse Name',
                                    selector: row=>row.spouse_name,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Location',
                                    selector: row=>row.location,
                                    sortable: false,
                                    width: "25%"
                                    },
                                ]}
                                data={farmerList}
                                highlightOnHover
                                selectableRows
                                onSelectedRowsChange={handleRowSelected}
                                progressPending={loading}
                            />
                        </Col>
                        <Col xs={12} className="text-end">
                            <Button className='text-white mt-4' color='primary' size="sm" onClick={add_selected_farmer}> Add Farmer </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>    

        </>
    )
}

export default AddPlanning;