import React, { useState, useEffect } from "react";
import { getTodayDate } from "../../hocs/dateServices";

import AddDemandRow from "./AddDemandRow";
import { notification } from '../../hocs/notification';
import axios from 'axios';
import { mainurl } from '../../../Mainurl';

import { Row, Col, Card, CardBody, CardHeader, Input, Label, Button, Form, CardFooter } from 'reactstrap';
import {FaPlusCircle} from 'react-icons/fa';

const GenrateDemand = ({back, ...props}) => {

    const [loading, setLoading] = useState('');

    const [state, setState] = useState('0');
    const [district, setDistrict] = useState('0');
    const [block, setBlock] = useState('0');
    
    const [stateOpt, setStateOpt] = useState('');
    const [districtOpt, setDistrictOpt] = useState('');
    const [blockOpt, setBlockOpt] = useState('');
    const [prodOpt, setProdOpt] = useState('');
    const [farmerList, setFarmerList] = useState('');

    const [season, setSeason] = useState('');
    const [category, setCategory] = useState('');
    const [product, setProduct] = useState('');
    const [unitName, setUnitName] = useState('');
    const [unit, setUnit] = useState();

    const [farmer, setFarmer] = useState('');
    const [farmerName, setFarmerName] = useState('');
    const [qty, setQty] = useState('');
    const [addList, setAddList] = useState('');
    const [addListArry, setAddListArry] = useState([]);
    const [currRow, setCurrRow] = useState('');
    const [updateRow, setUpdateRow] = useState(false);
    
    useEffect(()=> {
        get_product_list();
        get_state_list();
    },[]);

    const get_state_list = (e) => {
        
        let postData = [{"country_code": "1"}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_state_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.state_code}> {row.state_name}</option>
                    )
                });

                setStateOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_dist_list = (e) => {
        setState(e.target.value);
        let postData = [{"state_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_dist_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.district_code}> {row.district_name}</option>
                    )
                });

                setDistrictOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_block_list = (e) => {
        setDistrict(e.target.value);
        let postData = [{"dist_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.block_code}> {row.block_name}</option>
                    )
                });

                setBlockOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_farmer_list = (e) => {
        setLoading(true);
        setFarmerList('');
        e.preventDefault();
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": "101", "dist_id": district, "block_id": block, "vill_id":0}];
        axios({
            method: 'post',
            url: mainurl +'Farmer/box_farmer_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.farmer_code}> {row.f_name +" ("+ row.f_name_ne +")"} </option>
                    )
                });

            setFarmerList(list);
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
            //notify();
        });
    }

    const get_product_list = () => {
        let postData = [{"category_id": 1}]
        axios({
            method: 'post',
            url: mainurl + 'Commercial/product_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.prod_code}> {row.prod_name}</option>
                    )
                }) 

                setProdOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const add_row_handler = (e) => {
        e.preventDefault();

        let list = { "farmer": farmer, "farmerName": farmerName, "qty": qty, "unit": unit, "unitName": unitName };
        let tempArry = addListArry;
        
        tempArry.push(list);
        
        setAddListArry(tempArry);
        
        
        let total = 0;
        if (tempArry.length > 0) {
            let add_list = tempArry.map((row, index) => {
                total = total + Number(row.qty);
                return (
                    <AddDemandRow data={row} index={index} edit={()=>edit_handler(row, index)} del={()=>delete_handler(index)} key={index}  /> 
                )
            });
            setAddList(add_list);
            
            setFarmer('');
            setFarmerName('');
            setQty('');
        }
    }

    const edit_handler = (row, rowNo) => {
        setCurrRow(rowNo);
        setUpdateRow(true);
        setFarmer(row.farmer);
        setFarmerName(row.farmerName);
        setQty(row.qty);
    }

    const update_edit_handler = () => {
        let list = { "farmer": farmer, "farmerName": farmerName, "qty": qty, "unit": unit, "unitName": unitName };
        let updateList = addListArry;
        updateList.splice(currRow, 1, list);

        setAddListArry(updateList);
        
        
        let total = 0;
        if (updateList.length > 0) {
            let add_list = updateList.map((row, index) => {
                total = total + Number(row.qty);
                return (
                    <AddDemandRow data={row} index={index} edit={()=>edit_handler(row, index)} del={()=>delete_handler(index)} key={index}  /> 
                )
            });
            setAddList(add_list);
            
            setFarmer('');
            setFarmerName('');
            setQty('');
            setCurrRow('');
            setUpdateRow(false);
        }
    }

    const delete_handler = (rowNo) => {
        let delListArry = addListArry;
        
        delListArry.splice(rowNo, 1);
        setAddListArry(delListArry);
        let total = 0;
        if (delListArry.length > 0) {
          let add_list = delListArry.map((row, index) => {
            total = total + Number(row.qty);
            return (
                <AddDemandRow data={row} index={index} edit={()=>edit_handler(row)} del={delete_handler} key={index}  /> 
            )
          })
          setAddList(add_list);
        } else {
            setAddList('');
        }
    }

    const genrate_demand_handler = () => {
        let valid = true;

        if(season === '0'){
            let notify = notification({ message: "Add Season.", type: 'warn', time:true, position:'center' });
            notify();
            valid = false;
        }

        if(product === '0'){
            let notify = notification({ message: "Add Product.", type: 'warn', time:true, position:'center' });
            notify();
            valid = false;
        }
        
        if(addListArry.length < 1){
            let notify = notification({ message: "Add atleast one farmer demand.", type: 'warn', time:true, position:'center' });
            notify();
            valid = false;
        }

        
        if(valid){
            let farmerList = [];
            let qtyList = [];
            addListArry.map(row => {
                farmerList.push(row.farmer);
                qtyList.push(row.qty);
            });
    
            farmerList =JSON.stringify(farmerList);
            farmerList = farmerList.replaceAll('","', ',');
            farmerList = farmerList.replaceAll('"', '');
            farmerList = farmerList.replaceAll('[', '');
            farmerList = farmerList.replaceAll(']', '');
            
            qtyList =JSON.stringify(qtyList);
            qtyList = qtyList.replaceAll('","', ',');
            qtyList = qtyList.replaceAll('"', '');
            qtyList = qtyList.replaceAll('[', '');
            qtyList = qtyList.replaceAll(']', '');
    
            let postData = [{
                "org_id": sessionStorage.getItem('org_id'),
                "farmer_id": farmerList,
                "input_product_id": product,
                "input_uom": unit,
                "demand_gen_date": getTodayDate(),
                "farmer_qty": qtyList,
                "demand_fulfill_status": 0,
                "demand_collected_by": 0,
                "season_id": season,
            }];
            
            axios({
                method: 'post',
                url: mainurl + 'Commercial/input_demand_genration',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    if(response.data[0].ret_code === "101"){
                        let notify = notification({ message: "Demand added successfully.", type: 'info', time:true, position:'center' });
                        notify();
                        back();
                    }
                })
                .catch(error => {
                    setLoading(false);
                    //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                    //notify();
                });

        }
    }

    return(
        <>
            <Card className='mb-3' color="primary" outline>
                <CardHeader className='d-flex justify-content-between'>
                    <div>Genrate New Demand</div>
                    <Button className='text-white' color='primary' size="sm" onClick={back} > Back </Button>
                    
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs={3} className="mb-3">
                            <Label for="product">Season </Label>
                            <Input id="product" bsSize="sm" type="select" value={season} onChange={(e) => setSeason(e.target.value)} >
                                <option value="0"> --Select Season--</option>
                                <option value="1"> summer </option>
                                <option value="2"> Rainy </option>
                                <option value="3"> winter </option>
                            </Input>
                        </Col>
                        <Col xs={3} className="mb-3">
                            <Label for="product">Category </Label>
                            <Input id="product" bsSize="sm" type="select" value={category} onChange={(e) => setCategory(e.target.value)} >
                                <option value="0"> --Select Category--</option>
                                <option value="1"> Seeds </option>
                            </Input>
                        </Col>
                        <Col xs={3} className="mb-3">
                            <Label for="product">Product </Label>
                            <Input id="product" bsSize="sm" type="select" value={product} onChange={(e) => {setProduct(e.target.value); setAddListArry([]); setAddList('');}} >
                                <option value="0"> --Select Product--</option>
                                {prodOpt}
                            </Input>
                        </Col>
                        <Col xs={2} >
                            <Label for="unit"> Unit </Label>
                            <Input id="unit" bsSize="sm" type="select" value={unit} onChange={(e) => {setUnit(e.target.value);  setUnitName(e.target.selectedOptions[0].text);}} required>
                                <option value="0"> --Select unit--</option>
                                <option value="1"> Gm</option>
                                <option value="2"> Kg</option>
                                <option value="3"> Ton </option>
                                <option value="4"> Packet </option>
                            </Input>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm={3} className="mb-2">
                            <Label for="state"> State </Label>
                            <Input id="state" bsSize="sm" type="select" value={state} onChange={get_dist_list}>
                                <option value="0"> --Select State--</option>
                                {stateOpt}
                            </Input>
                        </Col>
                        <Col sm={3} className="mb-2">
                            <Label for="district"> District </Label>
                            <Input id="district" bsSize="sm" type="select" value={district} onChange={get_block_list}>
                                <option value="0"> --Select District--</option>
                                {districtOpt}
                            </Input>
                        </Col>
                        <Col sm={3} className="mb-2">
                            <Label for="block"> Block </Label>
                            <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => setBlock(e.target.value)}>
                                <option value="0"> --Select Block--</option>
                                {blockOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Button className='text-white mt-4' color='primary' size="sm" onClick={get_farmer_list} block> Get Farmer List </Button>
                        </Col>
                    </Row>
                    <hr/>
                    <Form onSubmit={add_row_handler}>
                        <Row>
                            <Col xs={3} >
                                <Label for="farmer"> Farmer </Label>
                                <Input id="farmer" bsSize="sm" type="select" value={farmer} onChange={(e) => {setFarmer(e.target.value); setFarmerName(e.target.selectedOptions[0].text);}} required>
                                    <option value="0"> --Select Farmer--</option>
                                    {farmerList}
                                </Input>
                            </Col>
                            
                            <Col xs={2} >
                                <Label for="qty"> Quantity </Label>
                                <Input id="qty" bsSize="sm" type="text" value={qty} onChange={(e) => setQty(e.target.value)} required/>  
                            </Col>
                            <Col xs={2} >
                                { updateRow ?
                                    <Button className='text-white mt-4' color='primary' size="sm" onClick={update_edit_handler}> <FaPlusCircle size={20} /> Update Row </Button>
                                    :
                                    <Button className='text-white mt-4' color='primary' size="sm" > <FaPlusCircle size={20} /> Add Row </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>

            <Card className='mb-3' color="primary" outline>
                <CardBody>
                    {addList.length > 0 &&
                        <Row className='mb-2'>
                            <Col xs={1}><b>#</b></Col>
                            <Col xs={2}><b>Farmer</b></Col>
                            <Col xs={2}><b>Qty</b></Col>
                            <Col xs={1}><b>Edit</b></Col>
                            <Col xs={1}><b>Delete</b></Col>
                        </Row>
                    }
                    {addList}
                </CardBody>
                <CardFooter>
                <Button className='text-white' color='primary' size="sm" onClick={genrate_demand_handler} > Final Submit </Button>
                </CardFooter>
            </Card>
        
        </>
    )
}
export default GenrateDemand;