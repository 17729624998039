import React, { useState, useEffect } from 'react';

import { getTodayDate } from "../../hocs/dateServices";
import { notification } from '../../hocs/notification';
import axios from 'axios';
import { mainurl } from '../../../Mainurl';
import AddProduceRow from './AddProduceRow';

import { Row, Col, Card, CardBody, CardHeader, Input, Label, Button, Form, CardFooter } from 'reactstrap';
import {FaPlusCircle} from 'react-icons/fa';

const ProducePurchase = ({back, ...props}) => {

    const [loading, setLoading] = useState('');

    const [state, setState] = useState('0');
    const [district, setDistrict] = useState('0');
    const [block, setBlock] = useState('0');
    
    const [stateOpt, setStateOpt] = useState('');
    const [districtOpt, setDistrictOpt] = useState('');
    const [blockOpt, setBlockOpt] = useState('');
    const [prodOpt, setProdOpt] = useState('');
    const [farmerList, setFarmerList] = useState('');

    const [farmer, setFarmer] = useState('');
    
    const [category, setCategory] = useState('');
    const [product, setProduct] = useState('');
    const [productName, setProductName] = useState('');
    const [rate, setRate] = useState('');
    const [qty, setQty] = useState('');
    const [unit, setUnit] = useState();
    const [unitName, setUnitName] = useState('');
    const [grade, setGrade] = useState();
    const [gradeName, setGradeName] = useState('');

    const [addList, setAddList] = useState('');
    const [addListArry, setAddListArry] = useState([]);
    const [currRow, setCurrRow] = useState('');
    const [updateRow, setUpdateRow] = useState(false);
    const [totalPrice, setTotalPrice] = useState('');
    
    useEffect(()=> {
        get_state_list();
    },[]);
    //
    const get_state_list = (e) => {
        
        let postData = [{"country_code": "1"}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_state_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.state_code}> {row.state_name}</option>
                    )
                });

                setStateOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_dist_list = (e) => {
        setState(e.target.value);
        let postData = [{"state_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_dist_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.district_code}> {row.district_name}</option>
                    )
                });

                setDistrictOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_block_list = (e) => {
        setDistrict(e.target.value);
        let postData = [{"dist_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.block_code}> {row.block_name}</option>
                    )
                });

                setBlockOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_farmer_list = (e) => {
        setLoading(true);
        setFarmerList('');
        e.preventDefault();
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": "101", "dist_id": district, "block_id": block, "vill_id":0}];
        axios({
            method: 'post',
            url: mainurl +'Farmer/box_farmer_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.farmer_code}> {row.f_name +" ("+ row.f_name_ne +")"} </option>
                    )
                });

            setFarmerList(list);
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
            //notify();
        });
    }

    const get_product_list = (id) => {
        let postData = [{"org_id": "101", "user_id": "101", "crop_type_id": id, "lang_id": 1}];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_master_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.crop_code}> {row.crop_name}</option>
                    )
                }) 

                setProdOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }
    //
    const add_row_handler = (e) => {
        e.preventDefault();

        let list = { "product": product, "productName": productName, "grade":grade, "gradeName":gradeName, "qty": qty, "unit": unit, "unitName": unitName, "rate": rate };
        let tempArry = addListArry;
        
        tempArry.push(list);
        
        setAddListArry(tempArry);
        
        
        let total = 0;
        if (tempArry.length > 0) {
            let add_list = tempArry.map((row, index) => {
                total = total + (Number(row.rate) * Number(row.qty));
                return (
                    <AddProduceRow data={row} index={index} edit={()=>edit_handler(row, index)} del={()=>delete_handler(index)} key={index}  /> 
                )
            });
            setAddList(add_list);
            setTotalPrice(total);
            
            setProduct('');
            setProductName('');
            setQty('');
            setUnit('');
            setUnitName('');
            setRate('');
            setGrade('');
            setGradeName('');
        }
    }

    const edit_handler = (row, rowNo) => {
        setCurrRow(rowNo);
        setUpdateRow(true);
        setProduct(row.product);
        setProductName(row.productName);
        setQty(row.qty);
        setUnit(row.unit);
        setUnitName(row.unitName);
        setRate(row.rate);
        setGrade(row.grade);
        setGradeName(row.gradeName);
        
    }

    const update_edit_handler = () => {
        let list = { "product": product, "productName": productName,"grade":grade, "gradeName":gradeName, "qty": qty, "unit": unit, "unitName": unitName, "rate": rate };
        let updateList = addListArry;
        updateList.splice(currRow, 1, list);

        setAddListArry(updateList);
        
        
        let total = 0;
        if (updateList.length > 0) {
            let add_list = updateList.map((row, index) => {
                total = total + (Number(row.rate) * Number(row.qty));
                return (
                    <AddProduceRow data={row} index={index} edit={()=>edit_handler(row, index)} del={()=>delete_handler(index)} key={index}  /> 
                )
            });
            setAddList(add_list);
            setTotalPrice(total);
            
            setProduct('');
            setProductName('');
            setQty('');
            setUnit('');
            setUnitName('');
            setRate('');
            setCurrRow('');
            setGrade('');
            setGradeName('');
            setUpdateRow(false);
        }
    }

    const delete_handler = (rowNo) => {
        let delListArry = addListArry;
        
        delListArry.splice(rowNo, 1);
        setAddListArry(delListArry);
        let total = 0;
        if (delListArry.length > 0) {
          let add_list = delListArry.map((row, index) => {
            total = total + (Number(row.rate) * Number(row.qty));
            return (
                <AddProduceRow data={row} index={index} edit={()=>edit_handler(row)} del={delete_handler} key={index}  /> 
            )
          })
          setAddList(add_list);
          setTotalPrice(total);
        } else {
            setAddList('');
            setTotalPrice(total);
        }
    }

    const sale_handler = () => {
        console.log(addListArry);

        let prodList = [];
        let qtyList = [];
        let uomList = [];
        let unitPriceList = [];
        let totalPriceList = [];
        let gradeList = [];

        addListArry.map(row => {
            prodList.push(row.product);
            qtyList.push(row.qty);
            uomList.push(row.unit);
            unitPriceList.push(row.rate);
            totalPriceList.push(Number(row.qty) * Number(row.rate));
            gradeList.push(row.grade);
        });

        prodList =JSON.stringify(prodList);
        prodList = prodList.replaceAll('","', ',');
        prodList = prodList.replaceAll('"', '');
        prodList = prodList.replaceAll('[', '');
        prodList = prodList.replaceAll(']', '');
        
        qtyList =JSON.stringify(qtyList);
        qtyList = qtyList.replaceAll('","', ',');
        qtyList = qtyList.replaceAll('"', '');
        qtyList = qtyList.replaceAll('[', '');
        qtyList = qtyList.replaceAll(']', '');
        
        uomList =JSON.stringify(uomList);
        uomList = uomList.replaceAll('","', ',');
        uomList = uomList.replaceAll('"', '');
        uomList = uomList.replaceAll('[', '');
        uomList = uomList.replaceAll(']', '');
        
        unitPriceList =JSON.stringify(unitPriceList);
        unitPriceList = unitPriceList.replaceAll('","', ',');
        unitPriceList = unitPriceList.replaceAll('"', '');
        unitPriceList = unitPriceList.replaceAll('[', '');
        unitPriceList = unitPriceList.replaceAll(']', '');
        
        totalPriceList =JSON.stringify(totalPriceList);
        totalPriceList = totalPriceList.replaceAll('","', ',');
        totalPriceList = totalPriceList.replaceAll('"', '');
        totalPriceList = totalPriceList.replaceAll('[', '');
        totalPriceList = totalPriceList.replaceAll(']', '');

        gradeList =JSON.stringify(gradeList);
        gradeList = gradeList.replaceAll('","', ',');
        gradeList = gradeList.replaceAll('"', '');
        gradeList = gradeList.replaceAll('[', '');
        gradeList = gradeList.replaceAll(']', '');

        let postData = [{"org_id": sessionStorage.getItem('org_id'),
        "user_id": sessionStorage.getItem('user_id'),
        "trn_type": 1,
        "farmer_id": farmer,
        "sale_date": getTodayDate(),
        "product_id": prodList,
        "grade_id": gradeList,
        "qty": qtyList,
        "uom": uomList,
        "unit_price": unitPriceList,
        "sale_price": totalPriceList,
        "sale_time": new Date().toLocaleTimeString(),
        "tax": "0",}];

        console.log(postData);
        axios({
            method: 'post',
            url: mainurl + 'Commercial/produce_sale_purchase',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                //if(response.data[0].ret_code === "101"){
                    let notify = notification({ message: "Submited successfully.", type: 'info', time:true, position:'center' });
                    notify();
                    back();
               // }
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    return(
        <>
            <Card className='mb-3' color="primary" outline>
                <CardHeader className='d-flex justify-content-between'>
                    <div>Produce Purchase</div>
                    <Button className='text-white' color='primary' size="sm" onClick={back} > Back </Button>
                    
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm={3} className="mb-2">
                            <Label for="state"> State </Label>
                            <Input id="state" bsSize="sm" type="select" value={state} onChange={get_dist_list}>
                                <option value="0"> --Select State--</option>
                                {stateOpt}
                            </Input>
                        </Col>
                        <Col sm={3} className="mb-2">
                            <Label for="district"> District </Label>
                            <Input id="district" bsSize="sm" type="select" value={district} onChange={get_block_list}>
                                <option value="0"> --Select District--</option>
                                {districtOpt}
                            </Input>
                        </Col>
                        <Col sm={3} className="mb-2">
                            <Label for="block"> Block </Label>
                            <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => setBlock(e.target.value)}>
                                <option value="0"> --Select Block--</option>
                                {blockOpt}
                            </Input>
                        </Col>
                        <Col md={2} className="mb-2">
                            <Button className='text-white mt-4' color='primary' size="sm" onClick={get_farmer_list} block> Get Farmer List </Button>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col xs={3} className="mb-3">
                            <Label for="product"> Farmer List</Label>
                                <Input id="farmer" bsSize="sm" type="select" value={farmer} onChange={(e) => {setFarmer(e.target.value); }} required>
                                    <option value="0"> --Select Farmer--</option>
                                    {farmerList}
                                </Input>
                        </Col>
                    </Row>
                    <hr/>
                    <Form onSubmit={add_row_handler}>

                        <Row>
                            <Col xs={2} className="mb-3">
                                <Label for="product">Category </Label>
                                <Input id="product" bsSize="sm" type="select" value={category} onChange={(e) => {setCategory(e.target.value);get_product_list(e.target.value)}} >
                                    <option value="0"> --Select Category--</option>
                                    <option value="1"> Nutrition </option>
                                    <option value="2"> Horticulture </option>
                                    <option value="3"> Aromatic </option>
                                    <option value="4"> Vegetable </option>
                                    <option value="5"> Apiary </option>
                                </Input>
                            </Col>
                            <Col xs={2} className="mb-3">
                                <Label for="product">Produce </Label>
                                <Input id="product" bsSize="sm" type="select" value={product} onChange={(e) => {setProduct(e.target.value); setProductName(e.target.selectedOptions[0].text);}} >
                                    <option value="0"> --Select Product--</option>
                                    {prodOpt}
                                </Input>
                            </Col>
                            <Col xs={2} >
                                <Label for="grade"> Grade </Label>
                                <Input id="grade" bsSize="sm" type="select" value={grade} onChange={(e) => {setGrade(e.target.value); setGradeName(e.target.selectedOptions[0].text);}} >
                                    <option value="0"> --Select Grade--</option>
                                    <option value="1"> Grade-1 (Good) </option>
                                    <option value="2"> Grade-2 (Average)</option>
                                    <option value="3"> Grade-3 (Poor)</option>
                                    
                                </Input>
                            </Col>
                            <Col xs={1} >
                                <Label for="qty"> Quantity </Label>
                                <Input id="qty" bsSize="sm" type="text" value={qty} onChange={(e) => setQty(e.target.value)} required/>  
                            </Col>
                            <Col xs={2} >
                                    <Label for="unit"> Unit </Label>
                                    <Input id="unit" bsSize="sm" type="select" value={unit} onChange={(e) => {setUnit(e.target.value);  setUnitName(e.target.selectedOptions[0].text);}} required>
                                        <option value="0"> --Select unit--</option>
                                        <option value="1"> Gm</option>
                                        <option value="2"> Kg</option>
                                        <option value="3"> Ton </option>
                                        <option value="4"> Packet </option>
                                    </Input>
                                </Col>
                            <Col xs={1} >
                                <Label for="qty"> Rate/Unit </Label>
                                <Input id="qty" bsSize="sm" type="text" value={rate} onChange={(e) => setRate(e.target.value)} required/>  
                            </Col>
                            
                            <Col xs={2} >
                                { updateRow ?
                                    <Button className='text-white mt-4' color='primary' size="sm" onClick={update_edit_handler}> <FaPlusCircle size={20} /> Update Row </Button>
                                    :
                                    <Button className='text-white mt-4' color='primary' size="sm" > <FaPlusCircle size={20} /> Add Row </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card> 

            
            <Card className='mb-3' color="primary" outline>
                <CardBody>
                    {addList.length > 0 &&
                        <Row className='mb-2'>
                            <Col xs={1}><b>#</b></Col>
                            <Col xs={2}><b>Product</b></Col>
                            <Col xs={2}><b>Grade</b></Col>
                            <Col xs={1}><b>Qty</b></Col>
                            <Col xs={1}><b>Rate/unit</b></Col>
                            <Col xs={1}><b>Total Price</b></Col>
                            <Col xs={1}><b>Edit</b></Col>
                            <Col xs={1}><b>Delete</b></Col>
                        </Row>
                    }
                    {addList}
                    <hr/>
                    <Row>
                        <Col xs={3}><b>Total Price: &#8377;{Number(totalPrice).toFixed(2)}</b></Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <Button className='text-white' color='primary' size="sm" onClick={sale_handler} > Final Submit </Button>
                </CardFooter>
            </Card>   
        </>
    )
}
export default ProducePurchase;