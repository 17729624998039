import React, { useState } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import { Row, Col, Card, CardBody, CardHeader, Input, Label, Button } from 'reactstrap';

import { FaPlusCircle, FaEye, FaFilePdf } from 'react-icons/fa';
import InputSale from '../../component/utility/setting/InputSale';
import InputPurchase from '../../component/utility/setting/InputPurchase';

const InputSalePurchase = () => {
    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);
    const [frDate, setFrDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [tableData, setTableData] = useState('');

    const get_sale_purchase_list = () => {
        let list = [
            {
                sl: 1,
                trans_id: "SH1001",
                trans_type: "Sale",
                date: "04-02-2023",
                amt: <div>&#8377; 1370.00</div>,
                invoice: <FaFilePdf className='me-3 cursor' color="#ff4136" size={20}/>,
                action: <FaEye className='me-3 cursor' color="#FFC000" size={20} />
            },
            {
                sl: 2,
                trans_id: "SH1002",
                trans_type: "Sale",
                date: "03-02-2023",
                amt: <div>&#8377; 2370.00</div>,
                invoice: <FaFilePdf className='me-3 cursor' color="#ff4136" size={20}/>,
                action: <FaEye className='me-3 cursor' color="#FFC000" size={20} />
            },
            {
                sl: 3,
                trans_id: "PH1002",
                trans_type: "Purchase",
                date: "03-02-2023",
                amt: <div>&#8377; 870.00</div>,
                invoice: <FaFilePdf className='me-3 cursor' color="#ff4136" size={20}/>,
                action: <FaEye className='me-3 cursor' color="#FFC000" size={20} />
            },
            {
                sl: 4,
                trans_id: "SH1006",
                trans_type: "Purchase",
                date: "03-02-2023",
                amt: <div>&#8377; 1070.00</div>,
                invoice: <FaFilePdf className='me-3 cursor' color="#ff4136" size={20}/>,
                action: <FaEye className='me-3 cursor' color="#FFC000" size={20} />
            },
        ];
        setTableData(list);
    }

    return (
        <>
            <Header />
            <Page title='Input Sale Purchase' breadcrumbs='Home / Input Sale Purchase'>
                {mode === 1 &&
                    <Row className="p-3">
                        <Col xs={12}>
                            <Card className='mb-3' color="primary" outline>
                                <CardBody>
                                    <Row>
                                        
                                        <Col xs={2} >
                                            <Button className='text-white mt-4' color='primary' size="sm" onClick={get_sale_purchase_list}> Get Sale Purchase list </Button>
                                        </Col>
                                        <Col xs={1}></Col>
                                        <Col xs={2} className="mb-2">
                                            <Button className='text-white mt-4' color='primary' size="sm" onClick={() => setMode(2)}> <FaPlusCircle size={20} /> Input Sale </Button>
                                        </Col>
                                        <Col xs={3} className="mb-2">
                                            <Button className='text-white mt-4' color='primary' size="sm" onClick={() => setMode(3)}> <FaPlusCircle size={20} /> Input Purchase </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className='mb-3' color="primary" outline>
                                <CardHeader>Sale Purchase List</CardHeader>
                                <CardBody>
                                    <DataTable
                                        title=""
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: '#',
                                                selector: row => row.sl,
                                                sortable: true,
                                                width: '80px'
                                            },
                                            {
                                                name: 'Transaction ID',
                                                selector: row => row.trans_id,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Transaction Type',
                                                selector: row => row.trans_type,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Sale / Purchase Date',
                                                selector: row => row.date,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Amount',
                                                selector: row => row.amt,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Invoice',
                                                selector: row => row.invoice,
                                                sortable: false,
                                            },
                                            {
                                                name: 'See Details',
                                                selector: row => row.action,
                                                sortable: false,
                                            },
                                        ]}
                                        data={tableData}
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </CardBody>
                            </Card>    
                        </Col>
                    </Row>
                }

                {mode === 2 && 
                    <Row className="p-3">
                        <Col xs={12}>
                            <InputSale back={()=>setMode(1)} />
                        </Col>
                    </Row>    
                }
                
                {mode === 3 && 
                    <Row className="p-3">
                        <Col xs={12}>
                            <InputPurchase back={()=>setMode(1)} />
                        </Col>
                    </Row>    
                }

            </Page>
            <Footer />
        </>
    )
}
export default InputSalePurchase;