import React, {useState, useEffect} from 'react';
import { notification } from '../../hocs/notification';
import axios from 'axios';
import { mainurl } from '../../../Mainurl';

import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../../style/color';

import { Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FaFilePdf, FaPlus, FaEye } from 'react-icons/fa';
import { MdAddCircle } from 'react-icons/md';
import AddCompliance from './AddCompliance';
import AddCertificate from './AddCertificate';


const financialData = [
    {
        sl: 1,
        name: "Nutritional Program",
        detail:"An equity certificate representing a 100% undivided beneficial ownership interest in the Trust.",
        figure: <div>&#8377; 40,000</div>,
    },
    {
        sl: 2,
        name: "Papaya Cultivation Program",
        detail:"the certificate of registration or other documents in lieu thereof establishing that the Goods supplied",
        figure: <div>&#8377; 1,25,000</div>,
    },
]

const Compliance = () => {

    const [mode, setMode] = useState(1);
    const [loadingCompliance, setLoadingCompliance] = useState(false);
    const [loadingCertificate, setLoadingCertificate] = useState(false);
    const [loadingFinancial, setLoadingFinancial] = useState(false);

    const [complianceData, setComplianceData] = useState('');
    const [certificateData, setCertificateData] = useState('');
    
    const [modalData, setModalData] = useState('');
    const [viewModal, setViewModal] = useState(false);

    useEffect(()=>{
        get_compliance_list();
        get_certificate_list();
    },[])

    const get_compliance_list = () => {
        setLoadingCompliance(true);
        let postData = [{"org_id": sessionStorage.getItem('org_id')}];

        axios({
            method: 'post',
            url: mainurl + 'Master/get_compliance_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                
                let list = response.data.map((row, index) => {
                    let frequency, status;
                    if(row.frequency === "1"){
                        frequency = "Yearly"; 
                    }else if(row.frequency === "2"){
                        frequency = "Half Yearly"; 
                    }else if(row.frequency === "3"){
                        frequency = "Quarterly (Three monthly)"; 
                    }else if(row.frequency === "4"){
                        frequency = "Monthly"; 
                    }else if(row.frequency === "5"){
                        frequency = "Weekly"; 
                    }

                    if(row.status === "1"){
                        status = "Pending"; 
                    }else if(row.status === "2"){
                        status = "Completed"; 
                    }
                    return(
                        {
                            sl: index + 1,
                            name: row.compliance_name,
                            detail: row.compliance_desc,
                            frequency: frequency,
                            last_update: row.last_update.split(" ")[0].split('-').reverse().join('-'),
                            pdf_link: <FaFilePdf color="#ff4136" size={20} />, 
                            action: <div>
                                <FaEye className='me-3 cursor' size={20} onClick={()=>show_return_dtl(row.compliance_type_id)}/>
                                <MdAddCircle className='cursor' size={20} onClick={add_return_dtl}/>
                            </div>
                        }
                    )
                });
                setComplianceData(list);
                setLoadingCompliance(false);
            })
            .catch(error => {
                setLoadingCompliance(false);
                //let notify = notification({ message: "compliance type list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const add_return_dtl = () => {
        "Master/add_compliance_return"
    }
    const show_return_dtl = (id) => {
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "compliance_id": 1}];
        axios({
            method: 'post',
            url: mainurl + 'Master/view_compliance_return',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                
                let list = response.data.map((row, index) => {
                    
                    return(
                        {
                            sl: index + 1,
                            return_amt: row.return_amt,
                            month: row.month,
                            year: row.year,
                            return_date: row.return_date.split(" ")[0].split('-').reverse().join('-'),
                            file: <FaFilePdf color="#ff4136" size={20} /> 
                        }
                    )
                });
                setModalData(list);
                setViewModal(true);
            })
            .catch(error => {
                //let notify = notification({ message: "compliance type list not Found! Try Again", type: 'error' });
                //notify();
                setLoadingCertificate(false);
            });
    }

    const get_certificate_list = () => {
        setLoadingCertificate(true);
        let postData = [{"org_id": sessionStorage.getItem('org_id')}];    

        axios({
            method: 'post',
            url: mainurl + 'Master/get_certificate_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                
                let list = response.data.map((row, index) => {
                    
                    return(
                        {
                            sl: index + 1,
                            name: row.certificate_name,
                            cert_no: row.certificate_no,
                            detail: row.certificate_desc,
                            issue: row.issue_date.split(" ")[0].split('-').reverse().join('-'),
                            expiry: row.expiry_date.split(" ")[0].split('-').reverse().join('-'),
                            pdf_link: <FaFilePdf color="#ff4136" size={20} /> 
                        }
                    )
                });
                setCertificateData(list);
                setLoadingCertificate(false);
            })
            .catch(error => {
                //let notify = notification({ message: "compliance type list not Found! Try Again", type: 'error' });
                //notify();
                setLoadingCertificate(false);
            });
    }

    return(
        <>
            {   (mode === 1 || mode === 2 || mode === 3) &&
                <Card className='mb-3' color="primary" outline>
                    <CardBody>
                        <Row>
                            <Col xs={12}>
                                <Button className={mode === 1 ? 'text-white me-3' : ' me-3'} color={ mode === 1 ? 'primary' : 'light'} size="sm" onClick={()=>setMode(1)}> Statutory Compliance</Button>
                                <Button className={mode === 2 ? 'text-white me-3' : ' me-3'} color={ mode === 2 ? 'primary' : 'light'} size="sm" onClick={()=>setMode(2)}> Certificates</Button>
                                <Button className={mode === 3 ? 'text-white me-3' : ' me-3'} color={ mode === 3 ? 'primary' : 'light'} size="sm" onClick={()=>setMode(3)}> Financials</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }    

            {   mode === 1 && 

                <Card color="primary" outline>
                    <CardHeader className='d-flex justify-content-between align-items-center'>
                        <div> Statutory Compliance Details </div>
                        <div> <Button className='text-white me-3' color='primary' size="sm" onClick={()=> setMode(4)} ><FaPlus /> Add New</Button> </div>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={12}>
                                <DataTable
                                    title=""
                                    customStyles={custTableStyles}
                                    columns={[
                                        {
                                            name: 'SL. No.',
                                            selector: row => row.sl,
                                            sortable: false,
                                            width: '80px'
                                        },
                                        {
                                            name: 'Name',
                                            selector: row => row.name,
                                            sortable: false,
                                            width: "15%",
                                            wrap: true
                                        },
                                        {
                                            name: 'Detail',
                                            selector: row => row.detail,
                                            sortable: false,
                                            width: "30%",
                                            wrap: true
                                        },
                                        {
                                            name: 'Frequency',
                                            selector: row => row.frequency,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Last Update',
                                            selector: row => row.last_update,
                                            sortable: false,
                                        },
                                        
                                        {
                                            name: 'PDF Link',
                                            selector: row => row.pdf_link,
                                            sortable: false,
                                        },
                                        
                                        {
                                            name: 'Action',
                                            selector: row => row.action,
                                            sortable: false,
                                        },
                                    ]}
                                    data={complianceData}
                                    highlightOnHover
                                    progressPending={loadingCompliance}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }

            { mode === 4 &&  
                <AddCompliance back={()=>{setMode(1);get_compliance_list();}} />
            }

            {   mode === 2 && 

                <Card color="primary" outline>
                    <CardHeader className='d-flex justify-content-between align-items-center'>
                        <div> Certificates Details </div>
                        <div> <Button className='text-white me-3' color='primary' size="sm" onClick={()=> setMode(5)} ><FaPlus /> Add New</Button> </div>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={12}>
                                <DataTable
                                    title=""
                                    customStyles={custTableStyles}
                                    columns={[
                                        {
                                            name: 'SL. No.',
                                            selector: row => row.sl,
                                            sortable: false,
                                            width: '80px'
                                        },
                                        {
                                            name: 'Name',
                                            selector: row => row.name,
                                            sortable: false,
                                            width: "20%",
                                            wrap: true
                                        },
                                        {
                                            name: 'Certificate No',
                                            selector: row => row.cert_no,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Detail',
                                            selector: row => row.detail,
                                            sortable: false,
                                            width: "30%",
                                            wrap: true
                                        },
                                        {
                                            name: 'Issue Date',
                                            selector: row => row.issue,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Expiry Date',
                                            selector: row => row.expiry,
                                            sortable: false,
                                        },
                                        
                                        {
                                            name: 'PDF Link',
                                            selector: row => row.pdf_link,
                                            sortable: false,
                                        },
                                    ]}
                                    data={certificateData}
                                    highlightOnHover
                                    progressPending={loadingCertificate}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }

            { mode === 5 &&  
                <AddCertificate back={()=>{setMode(2);get_certificate_list();}} />
            }

            {   mode === 3 && 

                <Card color="primary" outline>
                    <CardHeader className='d-flex justify-content-between align-items-center'>
                        <div> Financials Details </div>
                        <div> <Button className='text-white me-3' color='primary' size="sm" ><FaPlus /> Add New</Button> </div>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={12}>
                                <DataTable
                                    title=""
                                    customStyles={custTableStyles}
                                    columns={[
                                        {
                                            name: 'SL. No.',
                                            selector: row => row.sl,
                                            sortable: false,
                                            width: '80px'
                                        },
                                        {
                                            name: 'Name',
                                            selector: row => row.name,
                                            sortable: false,
                                            width: "20%",
                                            wrap: true
                                        },
                                        {
                                            name: 'Detail',
                                            selector: row => row.detail,
                                            sortable: false,
                                            width: "35%",
                                            wrap: true
                                        },
                                        {
                                            name: 'Figure',
                                            selector: row => row.figure,
                                            sortable: false,
                                        },
                                    ]}
                                    data={financialData}
                                    highlightOnHover
                                    progressPending={loadingFinancial}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }

            <Modal
                centered
                scrollable
                size="lg"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    Return Details
                </ModalHeader>
                <ModalBody>
                    <DataTable
                        title=""
                        customStyles={custTableStyles}
                        columns={[
                            {
                                name: '#',
                                selector: row => row.sl,
                                sortable: true,
                                width: '80px'
                            },
                            {
                                name: 'Amount',
                                selector: row => row.return_amt,
                                sortable: false,
                            },
                            {
                                name: 'Month',
                                selector: row => row.month,
                                sortable: false,
                            },
                            {
                                name: 'Year',
                                selector: row => row.year,
                                sortable: false
                            },
                            {
                                name: 'Date',
                                selector: row => row.return_date,
                                sortable: false
                            },
                            {
                                name: 'File',
                                selector: row => row.file,
                                sortable: false
                            },
                        ]}
                        data={modalData}
                        highlightOnHover
                        pagination
                        progressPending={!modalData}
                    />
                </ModalBody>
            </Modal>
            
        </>
    )
}

export default Compliance;