import React, { useState, useEffect } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import axios from 'axios';
import { mainurl } from '../../Mainurl';

import { Row, Col, Card, CardBody, CardHeader, Input, Label, Button,  Modal, ModalHeader, ModalBody } from 'reactstrap';

import { FaPlusCircle, FaEye } from 'react-icons/fa';
import GenrateDemand from '../../component/utility/setting/GenrateDemand';

const Demand = () => {
    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);
    const [frDate, setFrDate] = useState('');
    const [toDate, setToDate] = useState('');

    
    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState('');

    const [tableData, setTableData] = useState('');

    const get_demand_list = () => {
        
            let postData = [{"org_id": sessionStorage.getItem('org_id'), "fy": 0, "season": 0}]
            axios({
                method: 'post',
                url: mainurl + 'Commercial/input_demand_view',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    let list = response.data.map((row, index) => {
                        return(
                            {
                                sl: index + 1,
                                season: row.season_desc +"-"+ row.f_year,
                                product: row.prod_name +" ("+ row.prod_name_ne +")",
                                qty: row.qty +" "+ row.uom_desc,
                                farmer_count: row.farmer_list.length,
                                action: <FaEye className='me-3 cursor' color="#FFC000" size={20} onClick={()=>farmer_list_handler(row.farmer_list)} />
                            }
                        )
                    }) 
    
                    setTableData(list);
                })
                .catch(error => {
                    setLoading(false);
                    //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                    //notify();
                });

    }

    const farmer_list_handler = (dtl) => {
        
        const list = dtl.map((row, index) => {
            return{
                sl: index + 1,
                farmer_code: row.farmer_id,
                farmer_name: row.f_name + " (" + row.f_name_ne + ")",
                qty: row.input_demand_qty + " " + row.uom_desc
            }    
        })
        
        setModalData(list);
        setViewModal(true);
    }

    return (
        <>
            <Header />
            <Page title='Input Demand' breadcrumbs='Home / Input Demand'>
                {mode === 1 &&
                    <Row className="p-3">
                        <Col xs={12}>
                            <Card className='mb-3' color="primary" outline>
                                <CardBody>
                                    <Row>

                                        {/*<Col xs={3} >
                                            <Label for="frDate"> From Date </Label>
                                            <Input id="frDate" type='date' bsSize="sm" value={frDate} onChange={(e) => setFrDate(e.target.value)} required />
                                        </Col>
                                        <Col xs={3} >
                                            <Label for="toDate"> To Date </Label>
                                            <Input id="toDate" type='date' bsSize="sm" value={toDate} onChange={(e) => setToDate(e.target.value)} required />
                                        </Col>*/}
                                        <Col xs={3} >
                                            <Button className='text-white mt-4' color='primary' size="sm" onClick={get_demand_list}> Get Demand list </Button>
                                        </Col>
                                        <Col xs={3} className="mb-2">
                                            <Button className='text-white mt-4' color='primary' size="sm" onClick={() => setMode(2)}> <FaPlusCircle size={20} /> Genrate New Demand </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className='mb-3' color="primary" outline>
                                <CardHeader>View Demand</CardHeader>
                                <CardBody>
                                    <DataTable
                                        title=""
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: '#',
                                                selector: row => row.sl,
                                                sortable: true,
                                                width: '80px'
                                            },
                                            {
                                                name: 'Season',
                                                selector: row => row.season,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Product Name',
                                                selector: row => row.product,
                                                sortable: false,
                                                width: '25%'
                                            },
                                            {
                                                name: 'Demand Quantity',
                                                selector: row => row.qty,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Farmer Count',
                                                selector: row => row.farmer_count,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Farmer List',
                                                selector: row => row.action,
                                                sortable: false,
                                            },
                                        ]}
                                        data={tableData}
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </CardBody>
                            </Card>    
                        </Col>
                    </Row>
                }
                {mode === 2 && 
                    <Row className="p-3">
                        <Col xs={12}>
                            <GenrateDemand back={()=>setMode(1)} />
                        </Col>
                    </Row>    
                }


            </Page>
            <Footer />

            <Modal
                centered
                scrollable
                size="lg"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    Farmer Details
                </ModalHeader>
                <ModalBody>
                    <DataTable
                        title=""
                        customStyles={custTableStyles}
                        columns={[
                            {
                                name: '#',
                                selector: row => row.sl,
                                sortable: true,
                                width: '80px'
                            },
                            {
                                name: 'Farmer Code',
                                selector: row => row.farmer_code,
                                sortable: false,
                            },
                            {
                                name: 'Farmer Name',
                                selector: row => row.farmer_name,
                                sortable: false,
                                width: "50%"
                            },
                            {
                                name: 'Quantity',
                                selector: row => row.qty,
                                sortable: false
                            },
                        ]}
                        data={modalData}
                        highlightOnHover
                        pagination
                        progressPending={loading}
                    />
                </ModalBody>
            </Modal>
        </>
    )
}

export default Demand;