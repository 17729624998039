import React, {useState} from 'react';


import axios from 'axios';
import {mainurl} from '../../../Mainurl';

import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../../style/color';
import { Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import AddPlanning from './AddPlanning';
import { FaEye } from 'react-icons/fa';


const Planning = () => {

    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');
    
    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState('');

    let get_planning_list = () => {
            
            let postData = [{"org_id": sessionStorage.getItem('org_id')}]
            axios({
                method: 'post',
                url: mainurl + 'Activity/planning_view',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    let list = response.data.map((row, index) => {
                        return{
                            sl: index + 1,
                            date: row.planning_date.split(" ")[0].split('-').reverse().join('-'),
                            crop: row.crop_variety_name,
                            target_area: row.area +" "+ row.land_uom_desc,
                            total_farmer: row.total_farmer,
                            view: <FaEye className='cursor' color="#FFC000" size={20} onClick={()=>farmer_list_handler(row.farmer_detail)} />
                        } 
                        
                    });
    
                    setTableData(list);
                })
                .catch(error => {
                    setLoading(false);
                    //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                    //notify();
                });
    }

    const farmer_list_handler = (dtl) => {
        
        const list = dtl.map((row, index) => {
            return{
                sl: index + 1,
                farmer_code: row.farmer_code,
                farmer_name: row.f_name + " (" + row.f_name_ne + ")"
            }    
        })
        
        setModalData(list);
        setViewModal(true);
    }

    return(
        <>
            {   mode === 1 && 
                <div>

                    <Card className='mb-3' color="primary" outline>
                        <CardBody>
                            <Row>
                                <Col xs={12}>
                                    <Button className='text-white me-3' color='primary' size="sm" onClick={get_planning_list}> Get Plan List </Button>
                                    <Button className='text-white me-3' color='primary' size="sm" onClick={() => {setMode(2); get_planning_list();}} > Add New Plan </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card color="primary" outline>
                        <CardHeader> Planning List </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={12}>
                                    <DataTable
                                        title=""
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: 'SL. No.',
                                                selector: row => row.sl,
                                                sortable: false,
                                                width: '80px'
                                            },
                                            {
                                                name: 'Planning Date',
                                                selector: row => row.date,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Crop',
                                                selector: row => row.crop,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Target Area',
                                                selector: row => row.target_area,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Total Farmer',
                                                selector: row => row.total_farmer,
                                                sortable: false,
                                            },
                                            {
                                                name: 'View',
                                                selector: row => row.view,
                                                sortable: false,
                                            },
                                        ]}
                                        data={tableData}
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            }
            {   mode === 2 && 
                <AddPlanning back={()=> setMode(1)} />
            }

            <Modal
                centered
                scrollable
                size="lg"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    Farmer Details
                </ModalHeader>
                <ModalBody>
                    <DataTable
                        title=""
                        customStyles={custTableStyles}
                        columns={[
                            {
                                name: '#',
                                selector: row => row.sl,
                                sortable: true,
                                width: '80px'
                            },
                            {
                                name: 'Farmer Code',
                                selector: row => row.farmer_code,
                                sortable: false,
                            },
                            {
                                name: 'Farmer Name',
                                selector: row => row.farmer_name,
                                sortable: false,
                                width: "50%"
                            }
                        ]}
                        data={modalData}
                        highlightOnHover
                        pagination
                        progressPending={loading}
                    />
                </ModalBody>
            </Modal>
        </>
    )
}

export default Planning;