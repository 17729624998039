import React, { useState, useEffect } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import { notification } from '../../component/hocs/notification';

import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import axios from 'axios';
import { mainurl } from '../../Mainurl';

import { Row, Col, Card, CardBody, CardHeader, Input, Label, Button, Form, Modal, ModalHeader, ModalBody } from 'reactstrap';

import { FaPlusCircle } from 'react-icons/fa';
import AddNewPOP from '../../component/utility/setting/AddNewPOP';

const PackageOfPractice = () => {
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState(1);
    const [cropTypeOpt, setCropTypeOpt] = useState('');
    const [cropType, setCropType] = useState('');
    const [cropOpt, setCropOpt] = useState('');
    const [crop, setCrop] = useState('');
    const [cropVarietyOpt, setCropVarietyOpt] = useState('');
    const [cropVariety, setCropVariety] = useState('');
    const [popDays, setPopDays] = useState('');
    
    const [crrData, setCrrData] = useState('');
    const [tableData, setTableData] = useState('');
    const [viewModal, setViewModal] = useState('');

    useEffect(() => {
        get_crop_type();
    }, []);

    const get_crop_type = () => {
        let postData = [{ "org_id": sessionStorage.getItem('org_id'), "user_id": "10001", "lang_id": 1 }];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_type_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return (
                        <option value={row.crop_type_id}> {row.crop_type_name}</option>
                    )
                });

                setCropTypeOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_crop_list = (e) => {
        setCropOpt('');
        setCrop("0");
        setCropType(e.target.value);
        let postData = [{ "org_id": sessionStorage.getItem('org_id'), "user_id": "101", "crop_type_id": e.target.value, "lang_id": 1 }];

        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_master_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return (
                        <option value={row.crop_code}> {row.crop_name}</option>
                    )
                });

                setCropOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const crop_variety_list = (e) => {
        setCrop(e.target.value);
        let postData = [{ "org_id": sessionStorage.getItem('org_id'), "user_id": "101", "crop_code": e.target.value, "lang_id": 1 }]
        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_variety_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return (
                        <option value={row.crop_variety_code}> {row.crop_variety_name}</option>
                    )
                });

                setCropVarietyOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_pop_list = () => {
        setLoading(true);
        let postData = [{"org_id": sessionStorage.getItem('org_id')}];
        axios({
            method: 'post',
            url: mainurl + 'Traceability/get_pop_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    
                    return(
                        {
                            ids: index + 1 ,
                            crop_detail: row.crop_name +" ("+ row.crop_variety_name +")", 
                            pop_days: row.pop_days +" Days",
                            action: <Button className='text-white' color='primary' size="sm" onClick={()=> {setCrrData(row);setMode(2);}}>Details</Button>,
                        }
                    )
                });

                setTableData(list);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    
    const create_new_pop = (e) => {
        e.preventDefault();
        let postData = [{
            "org_id": sessionStorage.getItem('org_id'),
            "crop_code": crop,
            "crop_variety_code": cropVariety,
            "pop_days": popDays
        }]

        console.log(postData);

        axios({
            method: 'post',
            url: mainurl + 'Traceability/create_pop',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data[0].retcode === "101"){
                    let notify = notification({ message: "POP header created successfully.", type: 'info', time:true, position:'center' });
                    notify();
                    get_pop_list();
                    setViewModal(false);
                }
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    return (
        <>
            <Header />
            <Page title='Package Of Practice' breadcrumbs='Home / Package Of Practice (POP)'>
                {mode === 1 &&

                    <Row className="p-3">
                        <Col xs={12}>
                            <Card className='mb-3' color="primary" outline>
                                <CardBody>
                                    <Button className='text-white me-2' color='primary' size="sm" onClick={get_pop_list} > <FaPlusCircle size={20} /> Get POP List </Button>
                                    <Button className='text-white' color='primary' size="sm" onClick={() => setViewModal(true)}> <FaPlusCircle size={20} /> Add New POP </Button>
                                </CardBody>
                            </Card>

                            <Card className='' color="primary" outline>
                                <CardHeader>POP List</CardHeader>
                            <CardBody>
                            <DataTable
                                title=""
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                    name: 'Id',
                                    selector: row=>row.ids,
                                    sortable: true,
                                    width: '100px'
                                    },
                                    {
                                    name: 'Crop Detail',
                                    selector: row=>row.crop_detail,
                                    sortable: false,
                                    },
                                    {
                                    name: 'POP Days ',
                                    selector: row=>row.pop_days,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Action',
                                    selector: row=>row.action,
                                    sortable: false,
                                    },
                                ]}
                                data={tableData}
                                highlightOnHover
                                progressPending={loading}
                                />
                            </CardBody>
                        </Card>
                        </Col>
                    </Row>

                }
                {mode === 2 &&
                    <AddNewPOP data={crrData} back={() => setMode(1) } />
                }
            </Page>
            <Footer />

            <Modal
                centered
                scrollable
                size="lg"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    Add New POP
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={create_new_pop} >
                        <Row>
                            <Col sm={3} className="mb-2">
                                <Label for="type"> Crop Type </Label>
                                <Input id="type" bsSize="sm" type="select" value={cropType} onChange={get_crop_list}>
                                    <option value="0"> --Select Crop Type--</option>
                                    {cropTypeOpt}
                                </Input>
                            </Col>
                            <Col sm={3} className="mb-2">
                                <Label for="crop"> Crop </Label>
                                <Input id="crop" bsSize="sm" type="select" value={crop} onChange={crop_variety_list}>
                                    <option value="0"> --Select Crop --</option>
                                    {cropOpt}
                                </Input>
                            </Col>
                            <Col sm={3} className="mb-2">
                                <Label for="cropVariety"> Crop Variety </Label>
                                <Input id="cropVariety" bsSize="sm" type="select" value={cropVariety} onChange={(e) => setCropVariety(e.target.value)}>
                                    <option value="0"> --Select Crop --</option>
                                    {cropVarietyOpt}
                                </Input>
                            </Col>
                            <Col sm={3} className="mb-2">
                                <Label for="popDays"> POP Days </Label>
                                <Input id="popDays" bsSize="sm" type="text" value={popDays} onChange={(e) => setPopDays(e.target.value)} />
                            </Col>
                            <Col sm={3} className="mb-2">
                                <Button className='text-white' color='primary' size="sm"> Save </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}
export default PackageOfPractice;