import React, { useState } from 'react';


import { Row, Col, Button, Card, CardBody, CardHeader, Input, Label } from 'reactstrap';

import {FaPlusCircle, FaEdit} from 'react-icons/fa';
import {MdDeleteForever} from 'react-icons/md';

const MonitoringHeader = ({data, add_details, delete_h, edit_h, children, ...props}) => {

    return(
        <Card className='mb-2' color="primaryLight">
            <CardBody>
                <Row>
                    <Col md={3} className="mb-2">
                        <div><b>Measuring Point</b></div>
                        <div>{data?.m_desc}</div>            
                    </Col>
                    <Col md={2} className="mb-2">
                        <div><b>Sequence Number</b></div>
                        <div>{data?.m_seq_no}</div>              
                    </Col>
                    <Col md={2} className="mb-2">
                        <div><b>Measuring Day</b></div>
                        <div>After {data?.m_seq_day} Day</div>           
                    </Col>
                    <Col md={2} className="mb-2">
                        <Button className='' color='secondary' size="sm" onClick={add_details}>
                            <FaPlusCircle size={20} /> Add Details 
                        </Button>
                    </Col>
                    <Col md={1} className="mb-2">
                        <Button className='text-white' color='primary' size="sm" onClick={edit_h}>
                            <FaEdit size={20} /> Edit
                        </Button>              
                    </Col>
                    <Col md={2} className="mb-2">
                        <Button className='text-white' color='danger' size="sm" onClick={delete_h}>
                            <MdDeleteForever size={20} /> Delete
                        </Button>              
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        {children}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}
export default MonitoringHeader;