import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../../style/color';

import {FaUserFriends} from 'react-icons/fa';
import {MdAgriculture} from 'react-icons/md';
import {GiField, GiFruitBowl} from 'react-icons/gi';
import { mainurl, imgurl } from '../../../Mainurl';
import axios from 'axios';

import { Row, Col, Card, CardHeader, CardBody, FormGroup, Input, Label } from 'reactstrap';

const Operation = () => {

    const [loading, setLoading] = useState(false);
    const [stats, setStats] = useState('');
    const [tableData, setTableData] = useState('');
    const [cropTypeName, setCropTypeName] = useState('');
    const [cropTypeOpt, setCropTypeOpt] = useState('');
    const [cropType, setCropType] = useState('');
    const [cropOpt, setCropOpt] = useState('');
    const [crop, setCrop] = useState('');

    useEffect(()=>{
        get_db_stats();

        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": "10001", "lang_id": 1}];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_type_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.crop_type_id}> {row.crop_type_name}</option>
                    )
                });

                setCropTypeOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    },[])

    const get_db_stats = () => {
        let postData = [{"org_id": sessionStorage.getItem('org_id')}];
        axios({
            method: 'post',
            url: mainurl + 'Master/web_org_stats',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setStats(response.data[0]);
            })
            .catch(error => {
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_crop_list = (e) => {
        setCropOpt('');
        setCrop("0");
        setCropType(e.target.value);
        setTableData('');
        setCropTypeName(e.target.selectedOptions[0].text)
        let postData = [{"org_id": "101", "user_id": "101", "crop_type_id": e.target.value, "lang_id": 1}];

        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_master_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.crop_code}> {row.crop_name}</option>
                    )
                });

                setCropOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const crop_variety_list = (e) => {
        setTableData('');
        setLoading(true);
        setCrop(e.target.value);
        let postData = [{"org_id": "101", "user_id": "101", "crop_code": e.target.value, "lang_id": 1}]
        axios({
            method: 'post',
            url: mainurl + 'Master/box_crop_variety_list_active',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    let far; let status; let validity;
                    if(row.active != null){
                        far = row.active[0].max_farmer; 
                        status = <div className='text-success'>Active</div>;
                        validity = row.active[0].validity_date;
                    }else{
                        far = 0; 
                        status = <div className='text-muted'>Not Active</div>;
                        validity = 0;
                    }
                    return(
                        {
                            sl: index + 1,
                            name: row.crop_variety_name,
                            farmers: far,
                            validity: validity,
                            status: status,
                        }
                    )
                });

                setTableData(list);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }
    return(
        <>
        <Row className='p-3'>
                    <Col sm={3}>
                        <div className='d-box'>
                            <Row>
                                <Col xs={8}>
                                    <div>Total farmer</div>
                                    <div className='text-primary fs-20'>{stats?.farmer_count}</div>
                                </Col>
                                <Col xs={4}>
                                    <FaUserFriends className='mt-1' size={35} color="" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className='d-box'>
                            <Row>
                                <Col xs={8}>
                                    <div>Total Cultivation</div>
                                    <div className='text-primary fs-20'>{stats?.cultivation_count}</div>
                                </Col>
                                <Col xs={4}>
                                    <MdAgriculture className='mt-1' size={35} color="" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className='d-box'>
                            <Row>
                                <Col xs={8}>
                                    <div>Cultivation Area</div>
                                    <div className='text-primary fs-20'>402 Acre</div>
                                </Col>
                                <Col xs={4}>
                                    <GiField className='mt-1' size={35} color="" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className='d-box'>
                            <Row>
                                <Col xs={8}>
                                    <div>Total Crop</div>
                                    <div className='text-primary fs-20'>{stats?.active_crop}</div>
                                </Col>
                                <Col xs={4}>
                                    <GiFruitBowl className='mt-1' size={35} color="" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    
                </Row>
                <Row className='p-3'>
                    <Col xs={12}>
                        <Card className='mb-3' color="primary" outline>
                            <CardBody>
                                <Row>
                                    <Col sm={3} className="mb-2">
                                        <Label for="type"> Crop Type </Label>
                                        <Input id="type" bsSize="sm" type="select" value={cropType} onChange={get_crop_list}>
                                            <option value="0"> --Select Crop Type--</option>
                                            {cropTypeOpt}
                                        </Input>
                                    </Col>
                                    <Col sm={3} className="mb-2">
                                        <Label for="type"> Crop </Label>
                                        <Input id="type" bsSize="sm" type="select" value={crop} onChange={crop_variety_list}>
                                            <option value="0"> --Select Crop --</option>
                                            {cropOpt}
                                        </Input>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={6} className='mb-3'>
                        <Card className='h-100' color="primary" outline>
                            <CardHeader>{cropTypeName}</CardHeader>
                            <CardBody className='pt-0'>
                                <DataTable
                                    title=""
                                    customStyles={custTableStyles}
                                    columns={[
                                        {
                                            name: 'Name',
                                            selector: row => row.name,
                                            sortable: false,
                                            width: "40%"
                                        },
                                        {
                                            name: 'Max Farmers',
                                            selector: row => row.farmers,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Validity',
                                            selector: row => row.validity,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Status',
                                            selector: row => row.status,
                                            sortable: false,
                                        },
                                    ]}
                                    data={tableData}
                                    highlightOnHover
                                    progressPending={loading}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                   
                </Row>
        </>
    )
}

export default Operation; 