import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RegisterForm from '../component/utility/register/RegisterForm';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';

const RegisterOrg = () => {
    const navigate = useNavigate();
    

    const goto_login_page = () => {
        navigate('/');
    }

    return (
        <>
            <Row className='RegisterOrg' style={{ marginTop: "-70px" }}>
                <Col xs={12} className="p-2 borderBottom">
                    <h3 className='text-primary text-center'>Welcome To Boxfarming Technology</h3>
                </Col>
                <Col xs={12} className="p-2 borderBottom">
                    <RegisterForm back={goto_login_page} />
                </Col>
                <Col xs={12} className="p-2 pe-5 text-end">
                    <Button size="sm" color="primary" className='text-white' onClick={goto_login_page}> Back </Button>
                </Col>
            </Row>

        </>
    )
}

export default RegisterOrg;