import React, { useState, useEffect } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';
import { mainurl } from '../../Mainurl';

import { Row, Col, Card, CardHeader, CardBody, Form, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';
import {FaHeading, FaEdit, FaClipboardList, FaPlusCircle} from 'react-icons/fa';
import {MdDeleteForever, } from 'react-icons/md';

const Profile = () => {
    
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');


    const get_profile_details = () => {
        let postData = [{"org_id": sessionStorage.getItem('org_id'),}];
        axios({
            method: 'post',
            url: mainurl + 'Commercial/org_profile_view',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                setTableData(response.data[0]);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }


    return (
        <>
            <Header />
            <Page title='Organization Profile' breadcrumbs='Home / Organization Profile'>
                <Row className='p-3'>
                    
                    <Col md={12}>   
                        <Card className='mb-3' color="primary" outline>
                            <CardBody>
                                <Row>
                                <Col md={3}>
                                    <Button className='text-white' color='primary' size="sm" onClick={get_profile_details}><FaClipboardList className='me-1' size={20} /> Get Details </Button>
                                </Col>
                                </Row>
                            </CardBody>
                        </Card> 
                        <Card className='' color="primary" outline>
                            <CardBody>
                                <Row>
                                    <Col md={3} className="text-center mb-3">
                                        <div><b>Oraganization Name</b></div>
                                        <h5>{tableData?.org_name}</h5>
                                    </Col>
                                    <Col md={3} className="text-center mb-3">
                                        <div><b>Oraganization Type</b></div>
                                        <h5>{tableData?.org_type === '1' ? "NGO" : ""}</h5>
                                    </Col>
                                    <Col md={3} className="text-center mb-3">
                                        <div><b>Mobile Number</b></div>
                                        <h5>{tableData?.mobile_no}</h5>
                                    </Col>
                                    <Col md={3} className="text-center mb-3">
                                        <div><b>Address</b></div>
                                        <h5>{tableData?.address}</h5>
                                    </Col>
                                    <Col md={3} className="text-center mb-3">
                                        <div><b>Contact Person</b></div>
                                        <h5>{tableData?.contact_person}</h5>
                                    </Col>
                                    <Col md={3} className="text-center mb-3">
                                        <div><b>Authorized Capital</b></div>
                                        <h5>{tableData?.authorized_capital}</h5>
                                    </Col>
                                    <Col md={3} className="text-center mb-3">
                                        <div><b>Paidup Capital</b></div>
                                        <h5>{tableData?.paidup_capital}</h5>
                                    </Col>
                                </Row>
                            
                            </CardBody>
                        </Card>
                    </Col>
                    
                </Row>

            </Page>
            <Footer />

        </>
    )

}

export default Profile;