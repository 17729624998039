import React from 'react';
import {Row, Col, Card, CardBody} from 'reactstrap';

import {FaEdit} from 'react-icons/fa';
import {MdDeleteForever} from 'react-icons/md';

const AddDemandRow = ({data, index, edit, del, ...props}) => {
    return(
        <Row className='mb-2'>
            <Col xs={1}>{index + 1}</Col>
            <Col xs={2}>{data.farmerName}</Col>
            <Col xs={2}>{data.qty + " " + data.unitName}</Col>
            <Col xs={1}><FaEdit className="cursor" color="#FFC000" size={20} onClick={edit} /></Col>
            <Col xs={1}><MdDeleteForever className="cursor" color="#ff4136" size={20} onClick={del} /></Col>
        </Row>
    )
}
export default AddDemandRow;