import React, {useState, useEffect} from 'react';
import { notification } from '../../hocs/notification';
import axios from 'axios';
import { mainurl } from '../../../Mainurl';

import { Card, CardHeader, CardBody, Button, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

const AddCertificate = ({back, ...props}) => {
    const [certificateType, setCertificateType] = useState('');
    const [certificateTypeOpt, setCertificateTypeOpt] = useState('');
    const [certificateNo, setCertificateNo] = useState('');
    const [certificateDesc, setCertificateDesc] = useState('');
    const [issueDate, setIssueDate] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [certificateFile, setCertificateFile] = useState('');

    useEffect(()=>{
        get_certificate_type_list();
        
    }, [])

    const get_certificate_type_list = () => {
        axios({
            method: 'post',
            url: mainurl + 'Master/certificate_type_list',
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option key={index} value={row.certificate_type_id}> {row.certificate_name} </option>
                    )
                });

                setCertificateTypeOpt(list);
            })
            .catch(error => {
                //let notify = notification({ message: "compliance type list not Found! Try Again", type: 'error' });
                //notify();
            });
    }
    const add_certificate_handler = (e) => {
        e.preventDefault();

       let postData = [{
            "org_id": sessionStorage.getItem('org_id') ,
            "certificate_type_id": certificateType,
            "certificate_no": certificateNo,
            "certificate_desc": certificateDesc,
            "issue_date": issueDate,
            "expiry_date": expiryDate
        }]

        console.log(postData);
        axios({
            method: 'post',
            url: mainurl + 'Master/add_certificate',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
        .then(function (response) {
            let res = response.data[0].retcode;
            if(res === "101"){
                let notify = notification({ message: "Certificate added Successfully.", type: 'info', time:true, position:'center' });
                notify();
                back();
            }
        })
        .catch(error => {
            let notify = notification({ message: "Submit failed! Try Again", type: 'error',  time:true });
            notify();
        });
    }

    return(
        <>
            <Card color="primary" outline>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <div> Add Certificate </div>
                    <div> <Button className='text-white me-3' color='primary' size="sm" onClick={back} > Back</Button> </div>
                </CardHeader>
                <CardBody>
                    <Form onSubmit={add_certificate_handler}>
                        <Row>
                            <FormGroup row>
                                <Label for="certificateType" sm={2} > Certificate Type </Label>
                                <Col sm={4}>
                                    <Input id="certificateType" bsSize="sm" type="select" value={certificateType} onChange={(e) => setCertificateType(e.target.value)}>
                                        <option value="0"> --Select Certificate Type--</option>
                                        {certificateTypeOpt}
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="certificateNo" sm={2} > Certificate Number </Label>
                                <Col sm={4}>
                                    <Input id="certificateNo" type='text' bsSize="sm"  value={certificateNo} onChange={(e) => setCertificateNo(e.target.value)} required />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="certificateDesc" sm={2} > Certificate Description </Label>
                                <Col sm={4}>
                                    <Input id="certificateDesc" type='textarea' bsSize="sm"  value={certificateDesc} onChange={(e) => setCertificateDesc(e.target.value)} required />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="issueDate" sm={2} > Issue Date </Label>
                                <Col sm={4}>
                                    <Input id="issueDate" type='date' bsSize="sm"  value={issueDate} onChange={(e) => setIssueDate(e.target.value)} required />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="expiryDate" sm={2} > Expiry Date </Label>
                                <Col sm={4}>
                                    <Input id="expiryDate" type='date' bsSize="sm"  value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} required />
                                </Col>
                            </FormGroup>
                            
                            <FormGroup row>
                                <Label for="certificateFile" sm={2} > Add File </Label>
                                <Col sm={4}>
                                    <Input id="certificateFile" bsSize="sm" type="file" value={certificateFile} onChange={(e) => setCertificateFile(e.target.value)} />
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <hr />
                            <Col md={2} className="mb-2">
                                <Button className='text-white' color='primary' size="sm" block> Submit </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>        
        </>
    )
}
export default AddCertificate;