import React, { useState, useEffect } from 'react';

import {MdOutlineExpandMore, MdOutlineExpandLess} from 'react-icons/md';

import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';


import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';

import Chart from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
// bar chart data

const optionstack = {
    scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            stacked: true
        }]
    }
};

// dognut chart data
/*const optionsPie= {
    legend: { display: false, position: "right" },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      arc: {
        borderWidth: 0
      }
    },
  };*/

const optionsPie = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
        position: 'right',

    }
}
//
const KitchenGardenDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [graphData, setGraphData] = useState([]);
    const [doughnutData, setDoughnutData] = useState([]);

    useEffect(() => {
        get_graph();
    }, []);

    const get_graph = () => {
        let dataPie = {
            maintainAspectRatio: true,
            responsive: true,
            labels: ['ABC', 'EFG', 'HGS', 'KLBD',],
            datasets: [
                {
                    data: [560, 120, 420, 240],
                    backgroundColor: ['#4d8d6e', '#fcb929', '#83abd6', '#f76363'],
                }
            ]
        };

        setDoughnutData(dataPie);

        //

        let temp = [
            {
                label: "Harvesting",
                data: [112, 120, 65, 80, 62, 92, 85, 77, 75, 105],
                backgroundColor: '#4d8d6e',
            },
            {
                label: "Self Consume",
                data: [55, 60, 35, 50, 32, 52, 45, 32, 35, 48],
                backgroundColor: '#fcb929',
            }
        ];
        let dName = ['Amaranthus', 'Spinach', 'Ridge Gourd', 'Bitter Gourd', 'Bottle Gourd', 'Cucumber', 'Cowpea', 'Okra','Sponge Gourd', 'Brinjal'];

        setGraphData({ "labels": dName, "datasets": temp });
        setLoading(false);
    }

    return (
        <>

            <Row className='ps-2 pe-2 mb-3'>
                <Col md={6}>
                    <Card color="primary" outline style={{ maxHeight: "300px" }}>
                        <CardHeader>Geographical Data</CardHeader>
                        <CardBody>
                            <SimpleTreeView
                                aria-label="file system navigator"
                                defaultCollapseIcon={<MdOutlineExpandMore size={30} />}
                                defaultExpandIcon={<MdOutlineExpandLess size={30} />}
                                sx={{ height: 240, flexGrow: 1, maxWidth: '100%', overflowY: 'auto', overflowX: 'hidden' }}
                            >
                                <TreeItem itemId="1" label={<Row>
                                    <Col className='fs-12' xs={3}><b>Location</b></Col>
                                    <Col className='fs-12' xs={3}><b>Shareholder</b></Col>
                                    <Col className='fs-12' xs={3}><b>Distribution</b></Col>
                                    <Col className='fs-12' xs={3}><b>Cultivation</b></Col>
                                </Row>} >
                                    
                                </TreeItem>
                                <TreeItem itemId="2" label={<Row>
                                        <Col className='fs-12' xs={3}>Jharkhand</Col>
                                        <Col className='fs-12' xs={3}>1250</Col>
                                        <Col className='fs-12' xs={3}>708</Col>
                                        <Col className='fs-12' xs={3}>152 Acre</Col>
                                    </Row>}
                                >
                                    <TreeItem itemId="2_1" label={<Row>
                                        <Col className='fs-12' xs={3}>Ranchi</Col>
                                        <Col className='fs-12' xs={3}>80</Col>
                                        <Col className='fs-12' xs={3}>25</Col>
                                        <Col className='fs-12' xs={3}>10 Acre</Col>
                                    </Row>} >
                                        <TreeItem itemId="2_1_1" label={<Row>
                                            <Col className='fs-12' xs={3}>Namkum</Col>
                                            <Col className='fs-12' xs={3}>30</Col>
                                            <Col className='fs-12' xs={3}>15</Col>
                                            <Col className='fs-12' xs={3}>5 Acre</Col>
                                        </Row>} />
                                        <TreeItem itemId="2_1_2" label={<Row>
                                            <Col className='fs-12' xs={3}>Kanke</Col>
                                            <Col className='fs-12' xs={3}>38</Col>
                                            <Col className='fs-12' xs={3}>18</Col>
                                            <Col className='fs-12' xs={3}>7 Acre</Col>
                                        </Row>} />
                                    </TreeItem>
                                    <TreeItem itemId="2_2" label={<Row>
                                        <Col className='fs-12' xs={3}>Bokaro</Col>
                                        <Col className='fs-12' xs={3}>50</Col>
                                        <Col className='fs-12' xs={3}>15</Col>
                                        <Col className='fs-12' xs={3}>6 Acre</Col>
                                    </Row>} >

                                    </TreeItem>
                                    <TreeItem itemId="2_3" label={<Row>
                                        <Col className='fs-12' xs={3}>Purbi Singhbhum</Col>
                                        <Col className='fs-12' xs={3}>58</Col>
                                        <Col className='fs-12' xs={3}>14</Col>
                                        <Col className='fs-12' xs={3}>11 Acre</Col>
                                    </Row>} >

                                    </TreeItem>
                                    <TreeItem itemId="2_4" label={<Row>
                                        <Col className='fs-12' xs={3}>Deoghar</Col>
                                        <Col className='fs-12' xs={3}>78</Col>
                                        <Col className='fs-12' xs={3}>24</Col>
                                        <Col className='fs-12' xs={3}>16 Acre</Col>
                                    </Row>} >

                                    </TreeItem>
                                    
                                </TreeItem>
                            </SimpleTreeView>

                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card color="primary" outline style={{ minHeight: "300px" }}>
                        <CardHeader>Graphical Data</CardHeader>
                        <CardBody>
                            {!loading && <Bar options={optionstack} data={graphData} height="100%" />}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/*<Row className='ps-2 pe-2 mb-3'>
                <Col xs={4}>
                    <Card color="primary" outline>
                        <CardHeader>Cultivation</CardHeader>
                        <CardBody>
                            {!loading &&
                                <Doughnut
                                    data={doughnutData}
                                    options={optionsPie}
                                    height="150px"
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={4}>
                    <Card color="primary" outline>
                        <CardHeader>Distribution</CardHeader>
                        <CardBody>
                            {!loading &&
                                <Doughnut
                                    data={doughnutData}
                                    options={optionsPie}

                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={4}>
                    <Card color="primary" outline>
                        <CardHeader>Training</CardHeader>
                        <CardBody>
                            {!loading &&
                                <Doughnut
                                    data={doughnutData}
                                    options={optionsPie}

                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>*/}

        </>
    )
}

export default KitchenGardenDashboard;