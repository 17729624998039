import React, {useEffect, useState} from 'react';
import { notification } from '../../hocs/notification';

import axios from 'axios';
import { mainurl } from '../../../Mainurl';

import {FaHeading} from 'react-icons/fa';
import { Row, Col, Card, CardBody, CardHeader, Form, FormGroup, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';

const AddFarmer = ({back}) => {
    const [loading, setLoading] = useState(false);
    const [hindiNameLoading, setHindiNameLoading] = useState(false);
    const [regNo, setRegNo] = useState('0');
    const [name, setName] = useState('');
    const [nameHin, setNameHin] = useState('');
    const [spouseName, setSpouseName] = useState('');
    const [spouseNameHin, setSpouseNameHin] = useState('');
    const [age, setAge] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [category, setCategory] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [altMobileNo, setAltMobileNo] = useState('');

    const [state, setState] = useState('0');
    const [stateOpt, setStateOpt] = useState('');
    const [district, setDistrict] = useState('0');
    const [districtOpt, setDistrictOpt] = useState('');
    const [block, setBlock] = useState('0');
    const [blockOpt, setBlockOpt] = useState('');
    const [village, setVillage] = useState('0');
    const [villageOpt, setVillageOpt] = useState('');
    const [address, setAddress] = useState('');

    const [season, setSeason] = useState('0');
    const [seasonName, setSeasonName] = useState('');
    const [crop, setCrop] = useState('0');
    const [cropName, setCropName] = useState('');
    const [cropPtnList, setCropPtnList] = useState([]);
    const [cropPtnListData, setCropPtnListData] = useState([]);
    
    const [facilityType, setFacilityType] = useState('0');
    const [facilityTypeName, setFacilityTypeName] = useState('');
    const [facilityList, setFacilityList] = useState([]);
    const [facilityListData, setFacilityListData] = useState([]);

    const [plotNo, setPlotNo] = useState('');
    const [holdingNo, setHoldingNo] = useState('');
    const [area, setArea] = useState('');
    const [areaType, setAreaType] = useState('0');
    const [areaTypeName, setAreaTypeName] = useState('');
    const [landList, setLandList] = useState([]);
    const [landListData, setLandListData] = useState([]);
    //

    useEffect(()=>{
        get_state_list();
    },[]);
    //
    const get_state_list = (e) => {
        
        let postData = [{"country_code": "1"}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_state_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.state_code}> {row.state_name}</option>
                    )
                });

                setStateOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_dist_list = (e) => {
        setState(e.target.value);
        let postData = [{"state_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_dist_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.district_code}> {row.district_name}</option>
                    )
                });

                setDistrictOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_block_list = (e) => {
        setDistrict(e.target.value);
        let postData = [{"dist_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.block_code}> {row.block_name}</option>
                    )
                });

                setBlockOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }
    const get_village_list = (e) => {
        setBlock(e.target.value);

        let postData = [{"block_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_village_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.village_code}> {row.village_name}</option>
                    )
                });

                setVillageOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }
    //


    const add_handler = (e) => {
        e.preventDefault();

        let flag = true;

        if(gender === "0"){
            flag = false;
            let notify = notification({ message: "Please select gender.", type: 'warn' });
            notify();
        }
        if(category === "0"){
            flag = false;
            let notify = notification({ message: "Please select category.", type: 'warn' });
            notify();
        }
        if(state === "0"){
            flag = false;
            let notify = notification({ message: "Please select state.", type: 'warn' });
            notify();
        }
        if(district === "0"){
            flag = false;
            let notify = notification({ message: "Please select district.", type: 'warn' });
            notify();
        }
        if(block === "0"){
            flag = false;
            let notify = notification({ message: "Please select block.", type: 'warn' });
            notify();
        }
        if(village === "0"){
            flag = false;
            let notify = notification({ message: "Please select village.", type: 'warn' });
            notify();
        }

        if(flag){

            let postData = [{
                "org_id": sessionStorage.getItem('org_id'), 
                "user_id": "10001",
                "f_reg_no": regNo,
                "f_name": name,
                "f_name_ne": nameHin,
                "age": age,
                "dob": dob,
                "mobile_no": mobileNo,
                "mobile_no_2": altMobileNo,
                "gender": gender,
                "category": category,
                "f_spouse_name": spouseName,
                "f_spouse_name_ne": spouseNameHin,
                "shareholder": "1",
                "state_id": state,
                "dist_id": district,
                "block_id": block,
                "pan_id": "0",
                "vill_id": village,
                "address": address,
                "crop_pattern_list": cropPtnList,
                "facility_list": facilityList,
                "land_list": landList
            }];
    
            console.log(postData);
            axios({
                method: 'post',
                url: mainurl + 'Farmer/box_farmer_add',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
            .then(function (response) {
                if(response.data[0].retcode === 101){
                    let notify = notification({ message: "Farmer added Successfully.", type: 'info', time:true, position:'center' });
                    notify();
                    back();
                }
            })
            .catch(error => {
                let notify = notification({ message: "Submit failed! Try Again", type: 'error',  time:true });
                notify();
            });
        }

    }

    const translate_text_handler = () => {

    }

    const add_crop_pattern = () => {
        let flag = true;

        if(season === "0"){
            flag = false;
            let notify = notification({ message: "Please Select Season", type: 'warn' });
            notify();
        }
        if(crop === "0"){
            flag = false;
            let notify = notification({ message: "Please Select Crop", type: 'warn' });
            notify();
        }

        if(flag){

            let temp = cropPtnList;
            let cropDtl = {"crop_id": crop, "season_id": season, "crop_name": cropName, "season_name": seasonName};
            temp.push(cropDtl);
            
            setCropPtnList(temp);
            
            let list = temp.map((row, index) => {
                return (
                    <Row key={index}>
                        <Col xs={1}>{index + 1}</Col>
                        <Col xs={2}>{row.crop_name}</Col>
                        <Col xs={2}>{row.season_name}</Col>
                    </Row> 
                )
            })
            setCropPtnListData(list);
    
            setCrop('0');
            setSeason('0');
            setCropName('');
            setSeasonName('');
        }
    }

    const add_facility = () => {
        let flag = true;

        if(facilityType === "0"){
            flag = false;
            let notify = notification({ message: "Please Select Facility Type.", type: 'warn' });
            notify();
        }

        if(flag){

            let temp = facilityList;
            let facilityDtl = {"facility_master_id": facilityType, "typeName": facilityTypeName};
            temp.push(facilityDtl);
            setFacilityList(temp);
            
            let list = temp.map((row, index) => {
                return (
                    <Row key={index}>
                        <Col xs={1}>{index + 1}</Col>
                        <Col xs={2}>{row.typeName}</Col>
                    </Row> 
                )
            })
            setFacilityListData(list);
    
            setFacilityType('0');
            setFacilityTypeName('');
        }
    }

    const Add_land_area = () => {
        let flag = true;

        if(plotNo === ""){
            flag = false;
            let notify = notification({ message: "Please Add plot No.", type: 'warn' });
            notify();
        }
        if(holdingNo === ""){
            flag = false;
            let notify = notification({ message: "Please Add holding No.", type: 'warn' });
            notify();
        }
        if(area === ""){
            flag = false;
            let notify = notification({ message: "Please Add area.", type: 'warn' });
            notify();
        }
        if(areaType === "0"){
            flag = false;
            let notify = notification({ message: "Please select area type.", type: 'warn' });
            notify();
        }

        if(flag){

            let temp = landList;
            let landDtl = {"f_plot_no": plotNo, "holding_no": holdingNo, "holding_area":area, "holding_type_id":areaType, "typeName": areaTypeName};
            temp.push(landDtl);
            setLandList(temp);
            
            let list = temp.map((row, index) => {
                return (
                    <Row key={index}>
                        <Col xs={1}>{index + 1}</Col>
                        <Col xs={2}>{row.f_plot_no}</Col>
                        <Col xs={2}>{row.holding_no}</Col>
                        <Col xs={2}>{row.holding_area} Acre</Col>
                        <Col xs={2}>{row.typeName}</Col>
                    </Row> 
                )
            })
            setLandListData(list);
    
            setPlotNo('');
            setHoldingNo('');
            setArea('');
            setAreaType('0');
            setAreaTypeName('');
        }
    }

    return (
        <>
            <Card className='mb-3' color="primary" outline>
                <CardHeader> Add New Farmer </CardHeader>
                <CardBody>
                    <Form onSubmit={add_handler}>

                        <h5 className='text-primary borderDashed pb-1'>Basic Information</h5>

                        <FormGroup row>
                            <Label for="name" sm={2} > Registration Number (Optional) </Label>
                            <Col sm={4}>
                                <Input id="name" type='text' bsSize="sm"  value={regNo} onChange={(e) => setRegNo(e.target.value)} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={2} > Name </Label>
                            <Col sm={4}>
                                <Input id="name" type='text' bsSize="sm" onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={name} onChange={(e) => setName(e.target.value)} required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="hName" sm={2} > Name (Regional Language) </Label>
                            <Col sm={4}>
                                <InputGroup size="sm">
                                    <Input id="hName" type='text' value={nameHin} bsSize="sm" onChange={(e)=> setNameHin(e.target.value)} required/>
                                    <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                        {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="spouseName" sm={2} > Spouse Name </Label>
                            <Col sm={4}>
                                <Input id="spouseName" type='text' onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} bsSize="sm" value={spouseName} onChange={(e) => setSpouseName(e.target.value)} required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="hName" sm={2} > Name (Regional Language) </Label>
                            <Col sm={4}>
                                <InputGroup size="sm">
                                    <Input id="hName" type='text' value={spouseNameHin} bsSize="sm" onChange={(e)=> setSpouseNameHin(e.target.value)} required/>
                                    <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                        {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="age" sm={2} > Age </Label>
                            <Col sm={2}>
                                <Input id="age" type='text' bsSize="sm" maxLength={3} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={age} onChange={(e) => setAge(e.target.value)} required />
                            </Col>
                            <Col sm={2}>
                                <Input id="age" type='date' bsSize="sm" value={dob} onChange={(e) => setDob(e.target.value)} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="gender" sm={2} > Gender </Label>
                            <Col sm={4}>
                                <Input id="gender" bsSize="sm" type="select" value={gender} onChange={(e) => setGender(e.target.value)} required>
                                    <option value="0"> --Select Gender--</option>
                                    <option value="1"> Male </option>
                                    <option value="2"> Female </option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="category" sm={2} > Category </Label>
                            <Col sm={4}>
                                <Input id="category" bsSize="sm" type="select" value={category} onChange={(e) => setCategory(e.target.value)} required>
                                    <option value="0"> --Select Category--</option>
                                    <option value="1"> General </option>
                                    <option value="2"> OBC </option>
                                    <option value="3"> ST / SC </option>
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="mobileNo" sm={2} > Mobile Number</Label>
                            <Col sm={2}>
                                <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} placeholder="Mobile Number" required />
                            </Col>
                            <Col sm={2}>
                                <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={altMobileNo} onChange={(e) => setAltMobileNo(e.target.value)} placeholder="Alternate Mobile Number" />
                            </Col>
                        </FormGroup>

                        <h5 className='text-primary borderDashed mt-3 pb-1'>Geographical Information</h5>

                        <FormGroup row>
                            <Label for="state" sm={2} > State </Label>
                            <Col sm={4}>
                                <Input id="state" bsSize="sm" type="select" value={state} onChange={get_dist_list} required>
                                    <option value="0"> --Select State--</option>
                                    {stateOpt}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="district" sm={2} > District </Label>
                            <Col sm={4}>
                                <Input id="district" bsSize="sm" type="select" value={district} onChange={get_block_list} required>
                                    <option value="0"> --Select District--</option>
                                    {districtOpt}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="block" sm={2} > Block </Label>
                            <Col sm={4}>
                                <Input id="block" bsSize="sm" type="select" value={block} onChange={get_village_list} required>
                                    <option value="0"> --Select Block--</option>
                                    {blockOpt}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="village" sm={2} > Village </Label>
                            <Col sm={4}>
                                <Input id="village" bsSize="sm" type="select" value={village} onChange={(e) => setVillage(e.target.value)} required>
                                    <option value="0"> --Select Village--</option>
                                    {villageOpt}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="address" sm={2} > Address </Label>
                            <Col sm={4}>
                            <Input id="address" type='text' bsSize="sm" value={address} onChange={(e) => setAddress(e.target.value)} />
                            </Col>
                        </FormGroup>

                        <h5 className='text-primary borderDashed mt-3 pb-1'>Cropping Pattern</h5>

                        <Row>
                            <Col sm={2} className="mb-2">
                                <Label for="crop"> Season </Label>
                                <Input id="crop" bsSize="sm" type="select" value={season} onChange={(e)=>{setSeason(e.target.value); setSeasonName(e.target.selectedOptions[0].text)}} >
                                    <option value="0"> --Select Season --</option>
                                    <option value="1"> Summar </option>
                                    <option value="2"> Raniy </option>
                                    <option value="3"> Winter </option>
                                </Input>
                            </Col>
                            <Col sm={2} className="mb-2">
                                <Label for="crop"> Crop </Label>
                                <Input id="crop" bsSize="sm" type="select" value={crop} onChange={(e)=>{setCrop(e.target.value); setCropName(e.target.selectedOptions[0].text)}} >
                                    <option value="0"> --Select Crop--</option>
                                    <option value="1"> Bitter Gourd </option>
                                    <option value="2"> Chilli </option>
                                    <option value="3"> Brinjal </option>
                                    <option value="4"> Tomato </option>
                                    <option value="4"> Amranthus </option>
                                </Input>
                            </Col>
                            <Col sm={2} className="mb-2">
                                <Button className='text-white mt-4' color='primary' size="sm" onClick={add_crop_pattern}> Add </Button>
                            </Col>
                            <hr />
                            { (cropPtnList.length > 0) &&
                                <Row>
                                    <Col xs={1}><b>#</b></Col>
                                    <Col xs={2}><b>Crop</b></Col>
                                    <Col xs={2}><b>Season</b></Col>
                                </Row>    
                            }
                            { cropPtnListData }

                        </Row>

                        

                        <h5 className='text-primary borderDashed mt-3 pb-1'>Add Source of irrigation</h5>
                        <Row>
                            <Col sm={2} className="mb-2">
                                <Label for="facilityType"> Facility Type </Label>
                                <Input id="facilityType" bsSize="sm" type="select" value={facilityType} onChange={(e)=>{setFacilityType(e.target.value); setFacilityTypeName(e.target.selectedOptions[0].text)}} >
                                    <option value="0"> --Select Facility Type--</option>
                                    <option value="1"> Well </option>
                                    <option value="2"> Pond </option>
                                    <option value="3"> Cannel </option>
                                    <option value="4"> Rainfed </option>
                                    <option value="4"> Drip </option>
                                </Input>
                            </Col>
                            <Col sm={2} className="mb-2">
                                <Button className='text-white mt-4' color='primary' size="sm" onClick={add_facility}> Add </Button>
                            </Col>
                            <hr />
                            { (facilityList.length > 0) &&
                                <Row>
                                    <Col xs={1}><b>#</b></Col>
                                    <Col xs={2}><b>Facility Name</b></Col>
                                </Row>    
                            }
                            { facilityListData }

                        </Row>

                        <h5 className='text-primary borderDashed mt-3 pb-1'>Add Land Area</h5>

                        <Row className='mb-3'>
                            <Col sm={2} className="mb-2">
                                <Label for="plotNo"> Plot No  </Label>
                                <Input id="plotNo" type='text' bsSize="sm" value={plotNo} onChange={(e)=>setPlotNo(e.target.value)}/>
                            </Col>
                            <Col sm={2} className="mb-2">
                                <Label for="holdingNo"> Holding No </Label>
                                <Input id="holdingNo" type='text' bsSize="sm" value={holdingNo} onChange={(e)=>setHoldingNo(e.target.value)}/>
                            </Col>
                            <Col sm={2} className="mb-2">
                                <Label for="area"> Area(In Acre) </Label>
                                <Input id="area" type='text' bsSize="sm" value={area} onChange={(e)=>setArea(e.target.value)}/>
                            </Col>
                            <Col sm={2} className="mb-2">
                                <Label for="areaType"> Area Type </Label>
                                <Input id="areaType" bsSize="sm" type="select" value={areaType} onChange={(e)=>{setAreaType(e.target.value); setAreaTypeName(e.target.selectedOptions[0].text)}} >
                                    <option value="0"> --Select Area Type--</option>
                                    <option value="1"> don </option>
                                    <option value="2"> baid </option>
                                    <option value="3"> tanr </option>
                                    <option value="4"> kanali </option>
                                </Input>
                            </Col>
                            <Col sm={2} className="mb-2">
                                <Button className='text-white mt-4' color='primary' size="sm" onClick={Add_land_area}> Add </Button>
                            </Col>
                            <hr />
                            { (landList.length > 0) &&
                                <Row>
                                    <Col xs={1}><b>#</b></Col>
                                    <Col xs={2}><b>Plot No.</b></Col>
                                    <Col xs={2}><b>Holding No.</b></Col>
                                    <Col xs={2}><b>Area</b></Col>
                                    <Col xs={2}><b>Area Type</b></Col>
                                </Row>    
                            }
                            { landListData }
                            
                        </Row>
                        <Row>
                            <hr />
                            <Col md={2} className="mb-2">
                                <Button className='text-white' color='primary' size="sm" block> Submit </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
            <div className='text-end'>
                <Button className='text-white' color='primary' size="sm" onClick={back}> Back </Button>
            </div>
        </>
    )
}

export default AddFarmer;