import React, { useState, useEffect } from 'react';
import { notification } from '../../hocs/notification';
import axios from 'axios';
import { mainurl } from '../../../Mainurl';

import { Row, Col, Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Spinner, Button } from 'reactstrap';

const RegisterForm = ({ back }) => {
    
    const [loading, setLoading] = useState(true);
    const [orgName, setOrgName] = useState('');
    const [type, setType] = useState('');

    const [contactPerson, setContactPerson] = useState('');
    const [state, setState] = useState('');
    const [stateOpt, setStateOpt] = useState('');
    const [address, setAddress] = useState('');
    const [altMobileNo, setAltMobileNo] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');
    const [noFarmer, setNoFarmer] = useState('');
    const [authorizedCapital, setAuthorizedCapital] = useState('');
    const [paidupCapital, setPaidupCapital] = useState('');

    const [season, setSeason] = useState('0');
    const [seasonName, setSeasonName] = useState('');
    const [crop, setCrop] = useState('0');
    const [cropName, setCropName] = useState('');
    const [cropPtnList, setCropPtnList] = useState([]);
    const [cropPtnListData, setCropPtnListData] = useState([]);

    useEffect(()=>{
        get_state_list();
    },[]);
    //
    const get_state_list = (e) => {
        
        let postData = [{"country_code": "1"}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_state_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.state_code}> {row.state_name}</option>
                    )
                });

                setStateOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const add_handler = (e) => {
        e.preventDefault();
        let postData = [ {
            "org_type": type ,
            "org_name": orgName,
            "org_name_ne": orgName,
            "no_of_farmer": noFarmer,
            "authorized_capital": authorizedCapital,
            "paidup_capital": paidupCapital,
            "contact_person": contactPerson,
            "mobile_no": mobileNo,
            "alt_mobile_no": altMobileNo,
            "email": email,
            "state": state,
            "address": address,
            "crop_pattern_list": cropPtnList,
        }];

        if(true){
            axios({
                method: 'post',
                url: mainurl + 'Commercial/new_org_registration',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
            .then(function (response) {
                let user_id = response.data[0].user_id;
                let notify = notification({ message: "Organization register successfully. Your Web Login ID= "+user_id+". And Password is '1234'.", type: 'info', time:false, position:'center' });
                notify();
                back();
            })
            .catch(error => {
                let notify = notification({ message: "Submit failed! Try Again", type: 'error',  time:true });
                notify();
            });
        }

    }

    const add_crop_pattern = () => {
        let flag = true;

        if(season === "0"){
            flag = false;
            let notify = notification({ message: "Please Select Season", type: 'warn' });
            notify();
        }
        if(crop === "0"){
            flag = false;
            let notify = notification({ message: "Please Select Crop", type: 'warn' });
            notify();
        }

        if(flag){

            let temp = cropPtnList;
            let cropDtl = {"crop_id": crop, "season_id": season, "crop_name": cropName, "season_name": seasonName};
            temp.push(cropDtl);
            
            setCropPtnList(temp);
            
            let list = temp.map((row, index) => {
                return (
                    <Row key={index}>
                        <Col xs={1}>{index + 1}</Col>
                        <Col xs={2}>{row.crop_name}</Col>
                        <Col xs={2}>{row.season_name}</Col>
                    </Row> 
                )
            })
            setCropPtnListData(list);
    
            setCrop('0');
            setSeason('0');
            setCropName('');
            setSeasonName('');
        }
    }


    return (
        <div className=' p-5'>
            <Card className='mb-3' color="primary" outline>
                <CardHeader> Register New Organization </CardHeader>
                <CardBody>
                    <Form onSubmit={add_handler}>
                        <Row>
                            <FormGroup row>
                                <Label for="name" sm={2} > Organisation Name* </Label>
                                <Col sm={4}>
                                    <Input id="name" type='text' bsSize="sm" onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={orgName} onChange={(e) => setOrgName(e.target.value)} placeholder="Organisation Name" required />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label for="type" sm={2} > Type Of Organization* </Label>
                            <Col sm={4}>
                                <Input id="type" bsSize="sm" type="select" value={type} onChange={(e) => setType(e.target.value)} required>
                                    <option value="0"> --Select Type--</option>
                                    <option value="1"> FPO </option>
                                </Input>
                            </Col>
                        </FormGroup>
                            <h5 className='text-primary borderDashed mt-3 pb-1'>Contact Details</h5>
                            <FormGroup row>
                                <Label for="contactPerson" sm={2} > Contact Person Name* </Label>
                                <Col sm={4}>
                                    <Input id="contactPerson" type='text' bsSize="sm" onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} placeholder="Contact Person Name" required />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="mobileNo" sm={2} > Mobile Number*</Label>
                                <Col sm={2}>
                                    <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} placeholder="Mobile Number" required />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="altMobileNo" sm={2} > Alternate Mobile Number</Label>
                                <Col sm={2}>
                                    <Input id="altMobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={altMobileNo} onChange={(e) => setAltMobileNo(e.target.value)} placeholder="Alternate Mobile Number" />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="email" sm={2} > Organization Email* </Label>
                                <Col sm={4}>
                                    <Input id="email" type='text' bsSize="sm" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required/>
                                </Col>
                            </FormGroup>

                            <h5 className='text-primary borderDashed mt-3 pb-1'>Address Details</h5>

                            <FormGroup row>
                                <Label for="state" sm={2} > State* </Label>
                                <Col sm={4}>
                                    <Input id="state" bsSize="sm" type="select" value={state} onChange={(e) => setState(e.target.value)} required>
                                        <option value="0"> --Select State--</option>
                                        {stateOpt}
                                    </Input>
                                    
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="address" sm={2} > Address* </Label>
                                <Col sm={4}>
                                    <Input id="address" type='textarea' bsSize="sm" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Address" required />
                                </Col>
                            </FormGroup>
                            <h5 className='text-primary borderDashed mt-3 pb-1'>Other Details</h5>
                            <FormGroup row>
                                <Label for="noFarmer" sm={2} > Number of Shareholder* </Label>
                                <Col sm={4}>
                                    <Input id="noFarmer" type='text' bsSize="sm" value={noFarmer} onChange={(e) => setNoFarmer(e.target.value)} placeholder="Total Shareholder" required/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="authorizedCapital" sm={2} > Authorized Capital </Label>
                                <Col sm={4}>
                                    <Input id="authorizedCapital" type='text' bsSize="sm" value={authorizedCapital} onChange={(e) => setAuthorizedCapital(e.target.value)} placeholder="Authorized Capital" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="paidupCapital" sm={2} > Paidup Capital </Label>
                                <Col sm={4}>
                                    <Input id="paidupCapital" type='text' bsSize="sm" value={paidupCapital} onChange={(e) => setPaidupCapital(e.target.value)} placeholder="Paidup Capital" />
                                </Col>
                            </FormGroup>
                            
                            
                        <h5 className='text-primary borderDashed mt-3 pb-1'>Cropping Pattern</h5>

                        <Row>
                            <Col sm={2} className="mb-2">
                                <Label for="crop"> Season </Label>
                                <Input id="crop" bsSize="sm" type="select" value={season} onChange={(e)=>{setSeason(e.target.value); setSeasonName(e.target.selectedOptions[0].text)}} >
                                    <option value="0"> --Select Season --</option>
                                    <option value="1"> Summar </option>
                                    <option value="2"> Raniy </option>
                                    <option value="3"> Winter </option>
                                </Input>
                            </Col>
                            <Col sm={2} className="mb-2">
                                <Label for="crop"> Crop </Label>
                                <Input id="crop" bsSize="sm" type="select" value={crop} onChange={(e)=>{setCrop(e.target.value); setCropName(e.target.selectedOptions[0].text)}} >
                                    <option value="0"> --Select Crop--</option>
                                    <option value="1"> Bitter Gourd </option>
                                    <option value="2"> Chilli </option>
                                    <option value="3"> Brinjal </option>
                                    <option value="4"> Tomato </option>
                                    <option value="4"> Amranthus </option>
                                </Input>
                            </Col>
                            <Col sm={2} className="mb-2">
                                <Button className='text-white mt-4' color='primary' size="sm" onClick={add_crop_pattern}> Add </Button>
                            </Col>
                            <hr />
                            { (cropPtnList.length > 0) &&
                                <Row>
                                    <Col xs={1}><b>#</b></Col>
                                    <Col xs={2}><b>Crop</b></Col>
                                    <Col xs={2}><b>Season</b></Col>
                                </Row>    
                            }
                            { cropPtnListData }

                        </Row>

                            <hr />
                            <Col md={2} className="mb-2">
                                <Button className='text-white' color='primary' size="sm" block> Register </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}

export default RegisterForm;