import React, { useState, useEffect } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';
import AddFarmer from '../../component/utility/master/AddFarmer';

import { Row, Col, Card, CardHeader, CardBody, Form, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';
import {FaEye, FaEdit, FaPlusCircle, FaClipboardList} from 'react-icons/fa';
import {MdDeleteForever, MdAddCircle } from 'react-icons/md';
import ViewFarmer from '../../component/utility/master/ViewFarmer';

const Farmer = () => {
    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');
    const [f_data, setF_Data] = useState('');

    const farmer_list = () => {
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": "101", "dist_id": 0, "block_id": 0, "vill_id":0}];
        axios({
            method: 'post',
            url: 'https://api2.boxfarming.in/wsapi/v2/index.php/Farmer/box_farmer_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        {
                            ids: index + 1 ,
                            f_code: row.farmer_code,
                            name: row.f_name +" ("+ row.f_name_ne +")",
                            mobile: row.mobile_no,
                            spouse_name: row.f_spouse_name,
                            location: row.state_name +"/"+ row.district_name +"/"+ row.block_name +"/"+ row.village_name,
                            action: <div>
                                        <FaEye className='me-3 cursor' color="#FFC000" size={20} onClick={()=>view_handler(row)} />
                                        <FaEdit className='me-3 cursor' color="#006600" size={20} />
                                        <MdDeleteForever className='cursor' color="#f70000" size={20} />
                                    </div>
                        }
                    )
                });

                setTableData(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const view_handler = (data) => {
        setF_Data(data);
        setMode(3);
    }

    const back_handler = () => {
        setMode(1);
    }


    return (
        <>
            <Header />
            <Page title='Farmer' breadcrumbs='Home / Farmer'>
                <Row className='p-3'>
                    { mode === 1 &&
                    <Col xs={12}>
                        <Card className='mb-3' color="primary" outline>
                            <CardBody>
                                <Row>
                                    <Col md={2}>
                                        <Button className='text-white' color='primary' size="sm" block onClick={farmer_list}> <FaClipboardList className='me-1' size={20} /> Get Farmer List</Button>
                                    </Col>
                                    <Col md={2}>
                                        <Button className='text-white' color='primary' size="sm" block onClick={() => setMode(2)}> <FaPlusCircle className='me-1' size={20} /> Add New Farmer </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='' color="primary" outline>
                            <CardBody>
                                <DataTable
                                    title=""
                                    customStyles={custTableStyles}
                                    columns={[
                                        {
                                        name: 'Id',
                                        selector: row=>row.ids,
                                        sortable: true,
                                        width: '80px'
                                        },
                                        {
                                        name: 'Code',
                                        selector: row=>row.f_code,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Name',
                                        selector: row=>row.name,
                                        sortable: false,
                                        width: "20%"
                                        },
                                        {
                                        name: 'Mobile Number',
                                        selector: row=>row.mobile,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Spouse Name',
                                        selector: row=>row.spouse_name,
                                        sortable: false,
                                        },
                                        {
                                        name: 'Location',
                                        selector: row=>row.location,
                                        sortable: false,
                                        width: "25%"
                                        },
                                        {
                                        name: 'Action',
                                        selector: row=>row.action,
                                        sortable: false,
                                        },
                                    ]}
                                    data={tableData}
                                    highlightOnHover
                                    progressPending={loading}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    }

                    { mode === 2 &&
                        <Col xs={12}>
                            <AddFarmer back={()=>back_handler()}/>
                        </Col>
                    }
                    { mode === 3 &&
                        <Col xs={12}>
                            <ViewFarmer data={f_data} back={()=>back_handler()}/>
                        </Col>
                    }
                </Row>

            </Page>
            <Footer />

        </>
    )

}

export default Farmer;